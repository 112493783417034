import React from 'react';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ThemeProvider, GlobalFontFace } from '@match/react-component-library';
import { SuccessStoryPage } from 'modules/pages';
import { GlobalCssReset } from 'modules/components';
import { useBrandName, useLocale, useSiteCode } from 'modules/hooks';
import { themeBuilder } from 'utils/theme-builder';
import { SITECODE } from '@match/app-rules-resolver';

const App = () => {
  const { locale } = useLocale();
  const { siteCode } = useSiteCode();
  const { brandName } = useBrandName();
  const currentLocale = locale;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const messages = require(`i18n/${currentLocale}-messages.json`);
  const successTheme = themeBuilder(siteCode);

  const isStir = siteCode === SITECODE.STIR;
  const titleText = isStir ? 'Stir Success Stories' : 'MatchMade Success Stories'
  const metaText = `${brandName} has helped create over a million love stories. If you’ve found someone special on ${brandName}, spread the love! Share your story with us today.`
  const favicon = isStir ? 'favicon-stir.ico' : 'favicon.ico';

  return (
    <IntlProvider locale={currentLocale} messages={messages}>
      <ThemeProvider theme={successTheme}>
        <Helmet>
          <title>{titleText}</title>
          <meta name="description" content={metaText} />
          <link rel="icon" href={favicon}></link>
        </Helmet>
        <GlobalFontFace />
        <GlobalCssReset padding margins borderBox />
        <SuccessStoryPage />
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
