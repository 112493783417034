import { CultureCode, CULTURE_CODE } from '@match/app-rules-resolver';

export const useDateFormat = (cultureCode: CultureCode) => {
  switch (cultureCode) {
    case CULTURE_CODE.SPANISH_MEXICO:
    case CULTURE_CODE.PORTUGUESE: {
      return 'DD/MM/YYYY';
    }
    case CULTURE_CODE.JAPANESE:
    case CULTURE_CODE.CHINESE_TRADITIONAL:
    case CULTURE_CODE.KOREAN: {
      return 'YYYY/MM/DD';
    }
    default: {
      return 'MM/DD/YYYY';
    }
  }
};

// TODO add some testing
