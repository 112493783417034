import React from 'react';
import { Box, constants, Typography } from '@match/react-component-library';

const { FONT_SIZE, SPACE } = constants;

const Tip = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box marginTop={SPACE.XLARGE}>
      <Typography fontSize={FONT_SIZE.XSMALL}>{children}</Typography>
    </Box>
  );
};

export default Tip;
