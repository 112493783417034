import SITE from '../constants/sites';
import { SiteCode, SITECODE } from '@match/app-rules-resolver';

export const useSiteCode = () => {
  const location = window.location.href;

  let siteCode: SiteCode = SITECODE.NORTH_AMERICA;

  if (location.includes(SITE.JAPAN)) {
    siteCode = SITECODE.JAPAN;
  } else if (location.includes(SITE.STIR)) {
    siteCode = SITECODE.STIR;
  }

  return { siteCode };
};
