import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CULTURE_CODE } from '@match/app-rules-resolver';
import { Modal } from '@match/react-component-library';
import { useBrandName, useLocale } from 'modules/hooks';
import { TermsModalContent } from './TermsModalContent';
import { TermsModalContentJapan } from './TermsModalContentJapan';

const TERMS_HEADER = 'terms_header';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
  triggerElemRef?: HTMLElement;
}

const TermsModal = ({ isOpen, onClose, triggerElemRef }: TermsModalProps) => {
  const intl = useIntl();
  const { locale } = useLocale();
  const { brandName } = useBrandName();

  return (
    <Modal
      aria-labelledby={TERMS_HEADER}
      closeButtonLabel={intl.formatMessage(messages.closeBtn)}
      hasCloseButton
      isOpen={isOpen}
      onClose={onClose}
      scrollLock={false}
      tabIndex={1}
      triggerElemRef={triggerElemRef || document.body}
    >
      {locale === CULTURE_CODE.JAPANESE ? <TermsModalContentJapan /> : <TermsModalContent brandName={brandName} />}
    </Modal>
  );
};

export default TermsModal;

const messages = defineMessages({
  closeBtn: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:closeBtn',
    description: 'Label for modal close button',
    defaultMessage: 'Close',
  },
});
