import React, { useState, useEffect } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Formik, FormikProps, FormikErrors } from 'formik';
import moment from 'moment';
import { Box, constants, Loading, Typography } from '@match/react-component-library';
import { CULTURE_CODE, SITECODE } from '@match/app-rules-resolver';
import { RecaptchaFormData, useArkose } from '@match/react-recaptcha';

import { CultureCode } from '@match/app-rules-resolver';
import styled from 'packages/theming';
import { ContentBox } from 'modules/components/ContentBox';
import {
  Input,
  LocationTypeAhead,
  PhotoUploader,
  Select,
  StoryFormSection,
  SubmissionSection,
  TermsSection,
  TextArea,
} from './components';
import api from 'utils/api-services';
import relationshipStatusString from 'utils/relationship-status';
import { MEDIA_QUERY, PLATFORM_MAX_WIDTH } from 'modules/constants/media-queries';
import { useBrandName, useDateFormat, useLocale, useParseDate, useSiteCode } from 'modules/hooks';
import { FormData, LocationData, PostUserData, RelationshipStatus, UploadedPhoto } from 'types';
import { RELATIONSHIP_STATUS } from 'modules/constants/relationship-status';

const { FONT_WEIGHT, FONT_SIZE, SPACE, SEMANTIC_COLOR_NAME, FONT_FACE } = constants;

const initialValues = {
  name: '',
  partnerName: '',
  emailAddress: '',
  phoneNumber: '',
  streetAddress: '',
  location: '',
  relationshipStatus: RELATIONSHIP_STATUS.NONE,
  whenMet: '',
  headline: '',
  details: '',
  hasAccepted: false,
  canShare: false,
  birthday: '',
  firstMove: '',
  appUsagePurpose: '',
  whenFirstDate: '',
  whenSeriousRelationship: '',
  firstImpression: '',
  firstDateMemory: '',
  howBecomeRelationship: '',
};

interface StoryFormProps {
  onSubmit: () => void;
  onBack: () => void;
  onError: (err: string) => void;
}

const StoryForm = ({ onSubmit, onBack, onError }: StoryFormProps) => {
  const intl = useIntl();
  const { locale } = useLocale();
  const { siteCode } = useSiteCode();
  const { brandName } = useBrandName();

  const isJapan = locale === CULTURE_CODE.JAPANESE;
  const isStir = siteCode === SITECODE.STIR;

  const dateFormat = useDateFormat(intl.locale as CultureCode);
  const parseDate = useParseDate(dateFormat);

  const [isSubmitting, setSubmitting] = useState(false);
  const [relationshipStatuses, setRelationshipStatuses] = useState<RelationshipStatus[]>([]);
  const [photos, setPhotos] = useState<UploadedPhoto[]>([]);
  const [canShare, setCanShare] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);

  useEffect(() => {
    api
      .fetchSiteData()
      .then((res) => {
        if (res.data) {
          const relationshipStatuses = res.data.relationshipStatuses;
          if (isJapan) {
            const japanRelationshipStatuses = relationshipStatuses.filter(
              (relationshipStatus) =>
                relationshipStatus.relationshipStatusId === 1 ||
                relationshipStatus.relationshipStatusId === 3
            );
            setRelationshipStatuses(
              japanRelationshipStatuses.map((relationshipStatus) => ({
                id: relationshipStatus.relationshipStatusId,
                name: relationshipStatusString(relationshipStatus.relationshipStatusId, intl),
              }))
            );
          } else {
            setRelationshipStatuses(
              relationshipStatuses.map((relationshipStatus) => ({
                id: relationshipStatus.relationshipStatusId,
                name: relationshipStatusString(relationshipStatus.relationshipStatusId, intl),
              }))
            );
          }
        }
      })
      .catch((err) => {
        console.warn(err);
        onError(intl.formatMessage(messages.defaultError));
      });
  }, []);

  const validateForm = (values: FormData) => {
    const errors: FormikErrors<FormData> = {};

    const REQUIRED_FIELDS = [
      'name',
      'partnerName',
      'emailAddress',
      'location',
      'relationshipStatus',
      'whenMet',
      'headline',
      'details',
    ];

    if (isJapan) {
      REQUIRED_FIELDS.push.apply(REQUIRED_FIELDS, [
        'firstMove',
        'appUsagePurpose',
        'whenFirstDate',
        'whenSeriousRelationship',
      ]);
    }

    REQUIRED_FIELDS.forEach((field) => {
      const val = (values as any)[field]; // TODO resolve any
      if (!val || (typeof val === 'string' && !val.trim())) {
        (errors as any)[field] = 'Required'; // Error messages aren't exposed to user so don't need translation
      }
    });

    if (!errors.emailAddress && !values.emailAddress.trim().match(/^[^@\s]+@[^\.\s]+\.[^\s]+$/)) {
      errors.emailAddress = 'Invalid email';
    }

    if (!moment(values.whenMet, dateFormat).isValid()) {
      errors.whenMet = 'Invalid date';
    }

    return errors;
  };

  const handleSubmit = async (values: RecaptchaFormData<FormData>) => {
    setSubmitting(true);

    const {
      details,
      emailAddress,
      headline,
      name,
      partnerName,
      phoneNumber,
      relationshipStatus,
      streetAddress,
      whenMet,
      recaptchaResponse,
      firstMove,
      appUsagePurpose,
      whenFirstDate,
      whenSeriousRelationship,
      birthday,
      firstImpression,
      firstDateMemory,
      howBecomeRelationship,
    } = values;

    const { latitude, location, longitude }: LocationData = JSON.parse(values.location);

    const sc3DetailsResponse = [
      firstMove,
      appUsagePurpose,
      whenFirstDate,
      whenSeriousRelationship,
      firstImpression,
      firstDateMemory,
      howBecomeRelationship,
    ];

    const userData: PostUserData = {
      canShare,
      countryCode: isJapan ? 94 : isStir ? 941 : 1,
      details: details,
      emailAddress,
      hasAccepted,
      headline,
      isActive: true,
      name: name,
      latitude,
      location,
      longitude,
      partnerName: partnerName,
      phoneNumber: isJapan ? birthday : phoneNumber,
      photos,
      recaptchaResponse,
      relationshipStatus,
      streetAddress,
      whenMet: moment(whenMet, dateFormat).toISOString(),
      additionalInfo: isJapan ? { sc3DetailsResponse: sc3DetailsResponse } : undefined,
    };

    try {
      await api.createUser(userData);
      onSubmit();
    } catch (err) {
      console.warn(err);
    }
    setSubmitting(false);
  };

  const { recaptchaElement, recaptchaError, recaptchaScriptsReady, recaptchaSubmit } = useArkose<
    FormData
  >({
    onSubmit: handleSubmit,
    sitekey: '85800716-F435-4981-864C-8B90602D10F7',
  });

  useEffect(() => {
    if (recaptchaError) {
      onError(intl.formatMessage(messages.defaultError));
    }
  });

  const photoRequirementTotal = isJapan ? photos.length >= 2 : photos.length >= 1;

  const whenMetPlaceholder = isJapan
    ? intl.formatMessage(messages.whenMetJapan)
    : intl.formatMessage(messages.whenMet);

  const isSc3Mobi = isJapan && document.documentElement.clientWidth <= PLATFORM_MAX_WIDTH.MOBILE;

  return (
    <StoryFormContentBox
      display="flex"
      flex={2}
      marginRight={SPACE.XLARGE}
      marginTop={SPACE.XLARGE}
      maxWidth={648}
      minWidth={464}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values: FormData) => recaptchaSubmit(null, values)}
        validate={validateForm}
      >
        {({ handleSubmit, isValid }: FormikProps<FormData>) =>
          isSubmitting ? (
            <ContentBox width="100%" height="100%" center>
              <Loading size="large" />
            </ContentBox>
          ) : (
            <Box width="100%">
              <Box display="flex" justifyContent="center" flexDirection="column" width="100%">
                <Box display="flex" marginBottom={SPACE.MEDIUM} flexDirection="column" width="100%">
                  <Box marginTop={SPACE.MEDIUM} marginBottom={SPACE.SMALL}>
                    <Typography
                      as="h1"
                      fontSize={FONT_SIZE.LARGE}
                      fontWeight={FONT_WEIGHT.BOLD}
                      color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : SEMANTIC_COLOR_NAME.CORE3_100}
                      fontFamily={FONT_FACE.HEADING}
                    >
                      <FormattedMessage {...messages.storyFormHeader} />
                    </Typography>
                  </Box>
                  <Typography
                    fontSize={FONT_SIZE.XSMALL}
                    color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : SEMANTIC_COLOR_NAME.CORE3_100}
                    fontFamily={FONT_FACE.BODY}
                  >
                    <FormattedMessage {...messages.storyFormSubheader} values={{ br: <br /> }} />
                  </Typography>
                  {isSc3Mobi && (
                    <Typography fontSize={FONT_SIZE.XSMALL} color={SEMANTIC_COLOR_NAME.CORE3_100}>
                      <FormattedMessage
                        {...messages.giftCardDetailsLink}
                        values={{
                          a(text: string) {
                            return <a href="#giftCardDetails">{text}</a>;
                          },
                        }}
                      />
                    </Typography>
                  )}
                </Box>

                <StoryFormSection title={intl.formatMessage(messages.infoSection)}>
                  <FormRow display="flex" marginBottom={SPACE.XLARGE}>
                    <Input
                      autoFocus
                      hasMargin
                      name="name"
                      placeholder={intl.formatMessage(messages.name)}
                      required
                    />
                    <Input
                      name="partnerName"
                      placeholder={intl.formatMessage(messages.partnerName)}
                      required
                    />
                  </FormRow>

                  <FormRow display="flex" marginBottom={SPACE.XLARGE}>
                    <Input
                      hasMargin
                      name="emailAddress"
                      placeholder={intl.formatMessage(messages.email)}
                      required
                      type="email"
                    />
                    {isJapan ? (
                      <Input
                        name="birthday"
                        placeholder={intl.formatMessage(messages.birthdayPlaceHolder)}
                        required
                        parser={parseDate}
                        ignoreSpecialCharacterFilter
                      />
                    ) : (
                      <Input name="phoneNumber" placeholder={intl.formatMessage(messages.phone)} />
                    )}
                  </FormRow>

                  <FormRow display="flex" marginBottom={SPACE.XLARGE}>
                    <Input
                      hasMargin
                      name="streetAddress"
                      placeholder={intl.formatMessage(messages.streetAddress)}
                    />
                    <LocationTypeAhead
                      id="drpLocation"
                      name="location"
                      onError={() => onError(intl.formatMessage(messages.defaultError))}
                      placeholder={intl.formatMessage(messages.city)}
                      required
                    />
                  </FormRow>
                </StoryFormSection>

                <StoryFormSection title={intl.formatMessage(messages.relationshipSection)}>
                  <FormRow display="flex" marginBottom={SPACE.XLARGE}>
                    <Select
                      hasMargin
                      name="relationshipStatus"
                      options={relationshipStatuses}
                      placeholder={intl.formatMessage(messages.relationshipStatus)}
                      required
                    />
                    <Input
                      name="whenMet"
                      placeholder={whenMetPlaceholder}
                      required
                      parser={parseDate}
                      ignoreSpecialCharacterFilter
                    />
                  </FormRow>
                </StoryFormSection>

                <StoryFormSection title={intl.formatMessage(messages.storySection)}>
                  <Input
                    name="headline"
                    placeholder={intl.formatMessage(messages.headline)}
                    required
                  />
                  {isJapan && (
                    <React.Fragment>
                      <Box marginTop={SPACE.MEDIUM}>
                        <Select
                          name="firstMove"
                          options={[
                            { id: 1, name: intl.formatMessage(messages.firstMoveOption1) },
                            { id: 2, name: intl.formatMessage(messages.firstMoveOption2) },
                            { id: 3, name: intl.formatMessage(messages.firstMoveOption3) },
                            { id: 4, name: intl.formatMessage(messages.firstMoveOption4) },
                            { id: 5, name: intl.formatMessage(messages.firstMoveOption5) },
                            { id: 6, name: intl.formatMessage(messages.firstMoveOption6) },
                          ]}
                          placeholder={intl.formatMessage(messages.firstMove)}
                          required
                          textField
                        />
                      </Box>
                      <Box marginTop={SPACE.MEDIUM}>
                        <Select
                          name="appUsagePurpose"
                          options={[
                            { id: 1, name: intl.formatMessage(messages.appUsagePurposeOption1) },
                            { id: 2, name: intl.formatMessage(messages.appUsagePurposeOption2) },
                            { id: 3, name: intl.formatMessage(messages.appUsagePurposeOption3) },
                            { id: 5, name: intl.formatMessage(messages.appUsagePurposeOption5) },
                            { id: 6, name: intl.formatMessage(messages.appUsagePurposeOption6) },
                            { id: 7, name: intl.formatMessage(messages.appUsagePurposeOption7) },
                          ]}
                          placeholder={intl.formatMessage(messages.appUsagePurpose)}
                          required
                          textField
                        />
                      </Box>
                      <Box marginTop={SPACE.MEDIUM}>
                        <Select
                          name="whenFirstDate"
                          options={[
                            { id: 1, name: intl.formatMessage(messages.whenFirstDateOption1) },
                            { id: 2, name: intl.formatMessage(messages.whenFirstDateOption2) },
                            { id: 3, name: intl.formatMessage(messages.whenFirstDateOption3) },
                            { id: 4, name: intl.formatMessage(messages.whenFirstDateOption4) },
                            { id: 5, name: intl.formatMessage(messages.whenFirstDateOption5) },
                          ]}
                          placeholder={intl.formatMessage(messages.whenFirstDate)}
                          required
                          textField
                        />
                      </Box>
                      <Box marginTop={SPACE.MEDIUM}>
                        <Select
                          name="whenSeriousRelationship"
                          options={[
                            {
                              id: 1,
                              name: intl.formatMessage(messages.whenSeriousRelationshipOption1),
                            },
                            {
                              id: 2,
                              name: intl.formatMessage(messages.whenSeriousRelationshipOption2),
                            },
                            {
                              id: 3,
                              name: intl.formatMessage(messages.whenSeriousRelationshipOption3),
                            },
                            {
                              id: 4,
                              name: intl.formatMessage(messages.whenSeriousRelationshipOption4),
                            },
                          ]}
                          placeholder={intl.formatMessage(messages.whenSeriousRelationship)}
                          required
                          textField
                        />
                      </Box>
                    </React.Fragment>
                  )}
                  {isSc3Mobi && (
                    <Typography fontSize={FONT_SIZE.XSMALL}>
                      <FormattedMessage
                        {...messages.writingSuggestion}
                        values={{
                          a(text: string) {
                            return <a href="#stuckOnWhatToWrite">{text}</a>;
                          },
                        }}
                      />
                    </Typography>
                  )}
                  <TextArea
                    name="details"
                    placeholder={intl.formatMessage(messages.details)}
                    required
                  />
                  {isJapan && (
                    <React.Fragment>
                      <TextArea
                        name="firstImpression"
                        placeholder={intl.formatMessage(messages.firstImpression)}
                        required
                      />
                      <TextArea
                        name="firstDateMemory"
                        placeholder={intl.formatMessage(messages.firstDateMemory)}
                        required
                      />
                      <TextArea
                        name="howBecomeRelationship"
                        placeholder={intl.formatMessage(messages.howBecomeRelationship)}
                        required
                      />
                    </React.Fragment>
                  )}
                </StoryFormSection>

                <StoryFormSection
                  noBottomBorder
                  required
                  subtitle={intl.formatMessage(messages.photoSectionSubheader, { br: <br /> })}
                  title={intl.formatMessage(messages.photoSection)}
                >
                  <PhotoUploader
                    onChange={setPhotos}
                    onError={onError}
                    photoLimit={5}
                    photos={photos}
                  />
                </StoryFormSection>

                <TermsSection
                  canShare={canShare}
                  hasAccepted={hasAccepted}
                  brandName={brandName}
                  onAcceptedChange={setHasAccepted}
                  onShareChange={setCanShare}
                />

                {recaptchaElement}

                <SubmissionSection
                  disabled={
                    !isValid || !recaptchaScriptsReady || !photoRequirementTotal || !hasAccepted
                  }
                  onBack={onBack}
                  onSubmit={handleSubmit}
                />
              </Box>
            </Box>
          )
        }
      </Formik>
    </StoryFormContentBox>
  );
};

const StoryFormContentBox = styled(ContentBox)(({ theme }) => ({
  [MEDIA_QUERY.TABLET]: {
    marginLeft: theme.space.xlarge,
  },
  [MEDIA_QUERY.MOBILE]: {
    marginLeft: theme.space.none,
    marginRight: theme.space.none,
    minWidth: 280,
  },
}));

const FormRow = styled(Box)(({ theme }) => ({
  [MEDIA_QUERY.MOBILE]: {
    flexDirection: 'column',
    marginBottom: theme.space.none,
  },
}));

export default StoryForm;

const messages = defineMessages({
  storyFormHeader: {
    id: 'src/modules/components/StoryForm/StoryForm:storyFormHeader',
    description: 'header of story form',
    defaultMessage: 'Share your story',
  },
  storyFormSubheader: {
    id: 'src/modules/components/StoryForm/StoryForm:storyFormSubheader',
    description: 'instructions for submitting story form',
    defaultMessage: 'To submit your story, simply answer all of the questions below.',
  },
  giftCardDetailsLink: {
    id: 'src/modules/components/StoryForm/StoryForm:giftCardDetailsLink',
    description: 'text with link to details for gift cards',
    defaultMessage: 'Details about gift cards can be found <a>here</a>',
  },
  writingSuggestion: {
    id: 'src/modules/components/StoryForm/StoryForm:writingSuggestion',
    description: 'text with link to details on what to write',
    defaultMessage: "If you don't know what to write, click <a>here</a>",
  },
  submitButton: {
    id: 'src/modules/components/StoryForm/StoryForm:submitButton',
    description: 'button to submit story',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'src/modules/components/StoryForm/StoryForm:cancelButton',
    description: 'button to cancel form',
    defaultMessage: 'Cancel',
  },
  infoSection: {
    id: 'src/modules/components/StoryForm/StoryForm:infoSection',
    description: 'form section title',
    defaultMessage: 'Your information',
  },
  relationshipSection: {
    id: 'src/modules/components/StoryForm/StoryForm:relationshipSection',
    description: 'form section title',
    defaultMessage: 'Relationship Status',
  },
  storySection: {
    id: 'src/modules/components/StoryForm/StoryForm:storySection',
    description: 'form section title',
    defaultMessage: 'Your story',
  },
  photoSection: {
    id: 'src/modules/components/StoryForm/StoryForm:photoSection',
    description: 'form section title',
    defaultMessage: 'You told us, now show us!',
  },
  photoSectionSubheader: {
    id: 'src/modules/components/StoryForm/StoryForm:photoSectionSubheader',
    description: 'photo upload instructions specifying max number of photos as 5',
    defaultMessage: 'Add up to 5 photos of you and your partner',
  },
  name: {
    id: 'src/modules/components/StoryForm/StoryForm:name',
    description: "input label for user's full name",
    defaultMessage: 'Your full name',
  },
  partnerName: {
    id: 'src/modules/components/StoryForm/StoryForm:partnerName',
    description: "input label for partner's full name",
    defaultMessage: "Your partner's full name",
  },
  email: {
    id: 'src/modules/components/StoryForm/StoryForm:email',
    description: 'input label for email address',
    defaultMessage: 'Email address',
  },
  phone: {
    id: 'src/modules/components/StoryForm/StoryForm:phone',
    description: 'input label for phone number',
    defaultMessage: 'Phone number',
  },
  streetAddress: {
    id: 'src/modules/components/StoryForm/StoryForm:streetAddress',
    description: 'input label for street address',
    defaultMessage: 'Street address',
  },
  city: {
    id: 'src/modules/components/StoryForm/StoryForm:city',
    description: 'input label for city',
    defaultMessage: 'City',
  },
  relationshipStatus: {
    id: 'src/modules/components/StoryForm/StoryForm:relationshipStatus',
    description: 'input label for current relationship status',
    defaultMessage: 'Current status',
  },
  whenMet: {
    id: 'src/modules/components/StoryForm/StoryForm:whenMet',
    description:
      "input label for date when user's relationship with partner began, in number format: 2 digits for day and month, 4 for year, in localized order i.e. mm/dd/yyyy -> 02/04/2016 (February 4th, 2016)",
    defaultMessage: 'When it began - mm/dd/yyyy',
  },
  whenMetJapan: {
    id: 'src/modules/components/StoryForm/StoryForm:whenMetJapan',
    description:
      "input label for date when user's relationship with partner began, in number format: 4 digits for year, 2 for day and month, in localized order i.e. yyyy/mm/dd -> 2016/02/04 (February 4th, 2016)",
    defaultMessage: 'When it began - yyyy/mm/dd',
  },
  headline: {
    id: 'src/modules/components/StoryForm/StoryForm:headline',
    description: "input label for user's story headline",
    defaultMessage: 'First, the headline',
  },
  details: {
    id: 'src/modules/components/StoryForm/StoryForm:details',
    description: "textarea label for user's story",
    defaultMessage: 'Now the full story',
  },
  firstImpression: {
    id: 'src/modules/components/StoryForm/StoryForm:firstImpression',
    description: "textarea label for user's story - first impression",
    defaultMessage:
      'What was your first impression of them? What made you want to meet them? Please tell us more!',
  },
  firstDateMemory: {
    id: 'src/modules/components/StoryForm/StoryForm:firstDateMemory',
    description: "textarea label for user's story - first date memory",
    defaultMessage: 'Please share your memory of the first date.',
  },
  howBecomeRelationship: {
    id: 'src/modules/components/StoryForm/StoryForm:howBecomeRelationship',
    description: "textarea label for user's story - how date turned into a relationship",
    defaultMessage:
      'How did this first date lead to a relationship? Please tell us more about your couple and your experience on Match.',
  },
  defaultError: {
    id: 'src/modules/components/StoryForm/StoryForm:defaultError',
    description: 'error message indicating something is wrong with the site',
    defaultMessage:
      "It's not you, it's us. Try clicking the buttons again in a few minutes, or refreshing your browser.",
  },
  recaptchaError: {
    id: 'src/modules/components/StoryForm/StoryForm:recaptchaError',
    description: 'error message indicating that recaptcha validation failed',
    defaultMessage:
      'Recaptcha validation failed, please follow the recaptcha prompt below and submit again.',
  },
  firstMove: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMove',
    description: 'input label for first move input',
    defaultMessage: 'Who initiated the first move (Like/message)?',
  },
  appUsagePurpose: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurpose',
    description: 'input label for why using Match',
    defaultMessage: 'Why did you start using Match app?',
  },
  whenFirstDate: {
    id: 'src/modules/components/StoryForm/StoryForm:whenFirstDate',
    description: 'input label for when first date occurred',
    defaultMessage: 'How long did it take the first date after you started messaging?',
  },
  birthdayPlaceHolder: {
    id: 'src/modules/components/StoryForm/StoryForm:birthdayPlaceHolder',
    description: 'input label for birthday',
    defaultMessage: 'Your birthday',
  },
  whenSeriousRelationship: {
    id: 'src/modules/components/StoryForm/StoryForm:whenSeriousRelationship',
    description: 'input label for when relationship became serious',
    defaultMessage: 'How long did it take to be a serious relationship after the first date?',
  },
  firstMoveOption1: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMoveOption1',
    description: 'first move option 1',
    defaultMessage: 'Me (Female)',
  },
  firstMoveOption2: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMoveOption2',
    description: 'first move option 2',
    defaultMessage: 'Me (Male)',
  },
  firstMoveOption3: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMoveOption3',
    description: 'first move option 3',
    defaultMessage: 'Me (Not specified)',
  },
  firstMoveOption4: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMoveOption4',
    description: 'first move option 4',
    defaultMessage: 'Partner (Female)',
  },
  firstMoveOption5: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMoveOption5',
    description: 'first move option 5',
    defaultMessage: 'Partner (Male)',
  },
  firstMoveOption6: {
    id: 'src/modules/components/StoryForm/StoryForm:firstMoveOption6',
    description: 'first move option 6',
    defaultMessage: 'Partner (Not specified)',
  },
  appUsagePurposeOption1: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption1',
    description: 'appUsagePurpose option 1',
    defaultMessage: 'to look for a partner to marry',
  },
  appUsagePurposeOption2: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption2',
    description: 'appUsagePurpose option 2',
    defaultMessage: 'to look for a partner to be in a serious relationship',
  },
  appUsagePurposeOption3: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption3',
    description: 'appUsagePurpose option 3',
    defaultMessage: 'to look for a partner for casual dating',
  },
  appUsagePurposeOption4: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption4',
    description: 'appUsagePurpose option 4',
    defaultMessage: 'to look for a partner for one night',
  },
  appUsagePurposeOption5: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption5',
    description: 'appUsagePurpose option 5',
    defaultMessage: 'to look for a friend',
  },
  appUsagePurposeOption6: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption6',
    description: 'appUsagePurpose option 6',
    defaultMessage: 'to look for sparkle joy in my life',
  },
  appUsagePurposeOption7: {
    id: 'src/modules/components/StoryForm/StoryForm:appUsagePurposeOption7',
    description: 'appUsagePurpose option 7',
    defaultMessage: 'none of the above',
  },
  whenFirstDateOption1: {
    id: 'src/modules/components/StoryForm/StoryForm:whenFirstDateOption1',
    description: 'whenFirstDate option 1',
    defaultMessage: 'within 1 week',
  },
  whenFirstDateOption2: {
    id: 'src/modules/components/StoryForm/StoryForm:whenFirstDateOption2',
    description: 'whenFirstDate option 2',
    defaultMessage: 'within 2 weeks',
  },
  whenFirstDateOption3: {
    id: 'src/modules/components/StoryForm/StoryForm:whenFirstDateOption3',
    description: 'whenFirstDate option 3',
    defaultMessage: 'within 1 month',
  },
  whenFirstDateOption4: {
    id: 'src/modules/components/StoryForm/StoryForm:whenFirstDateOption4',
    description: 'whenFirstDate option 4',
    defaultMessage: 'within 3 months',
  },
  whenFirstDateOption5: {
    id: 'src/modules/components/StoryForm/StoryForm:whenFirstDateOption5',
    description: 'whenFirstDate option 5',
    defaultMessage: 'more than 3 months',
  },
  whenSeriousRelationshipOption1: {
    id: 'src/modules/components/StoryForm/StoryForm:whenSeriousRelationshipOption1',
    description: 'whenSeriousRelationship option 1',
    defaultMessage: 'on the first date',
  },
  whenSeriousRelationshipOption2: {
    id: 'src/modules/components/StoryForm/StoryForm:whenSeriousRelationshipOption2',
    description: 'whenSeriousRelationship option 2',
    defaultMessage: 'on the second date',
  },
  whenSeriousRelationshipOption3: {
    id: 'src/modules/components/StoryForm/StoryForm:whenSeriousRelationshipOption3',
    description: 'whenSeriousRelationship option 3',
    defaultMessage: 'on the third date',
  },
  whenSeriousRelationshipOption4: {
    id: 'src/modules/components/StoryForm/StoryForm:whenSeriousRelationshipOption4',
    description: 'whenSeriousRelationship option 4',
    defaultMessage: 'more than above',
  },
});
