import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useField } from 'formik';
import {
  Box,
  constants,
  TextArea as RCLTextArea,
  Typography,
} from '@match/react-component-library';
import { removeDangerousSpecialCharacters } from 'utils/remove-dangerous-special-characters';

const { FONT_SIZE, SEMANTIC_COLOR_NAME, SPACE } = constants;

interface TextAreaProps {
  name: string;
  placeholder: string;
  required?: boolean;
}

const TextArea = ({ name, placeholder, required }: TextAreaProps) => {
  const [{ onBlur, onChange, value }, { error, touched }] = useField(name);

  const filteredValue = removeDangerousSpecialCharacters(value);
  return (
    <Box
      alignItems="flex-end"
      display="flex"
      flexDirection="column"
      marginY={SPACE.XLARGE}
      width="100%"
    >
      <RCLTextArea
        width="100%"
        hasError={!!error && !!touched}
        maxLength={1000}
        minimal
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={`${placeholder}${required ? ' *' : ''}`}
        rows={9}
        value={filteredValue}
        variant="variant1"
      />
      <Box as="span" marginTop={SPACE.SMALL}>
        <Typography color={SEMANTIC_COLOR_NAME.DISABLED} fontSize={FONT_SIZE.XXXSMALL}>
          <FormattedMessage {...messages.textareaCount} values={{ count: value.length }} />
        </Typography>
      </Box>
    </Box>
  );
};

const messages = defineMessages({
  textareaCount: {
    id: 'src/modules/components/StoryForm/components/TextArea/TextArea:textareaCount',
    description: 'count of how many characters the user has entered out of 1000',
    defaultMessage: '{count} of 1000 characters',
  },
});

export default TextArea;
