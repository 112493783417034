const SITE_NAME: {
  JAPAN: string;
  MATCH: string;
  OURTIME: string;
  PORTUGUESE: string;
  SPANISH: string;
  STIR: string;
} = {
  JAPAN: 'Match',
  MATCH: 'Match',
  OURTIME: 'Match',
  PORTUGUESE: 'Match',
  SPANISH: 'Match',
  STIR: 'Stir',
};

export default SITE_NAME;
export type Site = typeof SITE_NAME[keyof typeof SITE_NAME];
