import React, { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CULTURE_CODE, SITECODE } from '@match/app-rules-resolver';
import {
  ClickHandler,
  Box,
  Checkbox,
  constants,
  Radio,
  Typography,
} from '@match/react-component-library';
import styled from 'packages/theming';
import { useLocale, useSiteCode } from 'modules/hooks';
import { TermsModal } from './components';

const { SEMANTIC_COLOR_NAME, SPACE } = constants;

interface TermsSectionProps {
  canShare: boolean;
  hasAccepted: boolean;
  brandName: string;
  onAcceptedChange: (value: boolean) => void;
  onShareChange: (value: boolean) => void;
}

const TermsSection = ({
  canShare,
  hasAccepted,
  brandName,
  onAcceptedChange,
  onShareChange,
}: TermsSectionProps) => {
  const { locale } = useLocale();
  const { siteCode } = useSiteCode();
  const modalTriggerRef = useRef().current;
  const [showTermsModal, setShowTermsModal] = useState(false);
  const checkboxId = 'HasAcceptedCheckbox';
  const canShareFalseId = 'CanShareFalseRadio';
  const openTermsModal = () => setShowTermsModal(true);
  const closeTermsModal = () => setShowTermsModal(false);

  const handleAcceptedChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      onAcceptedChange(e.currentTarget.checked);
    }
  };

  const handleAllowSharing = () => onShareChange(true);
  const handlePreventSharing = () => onShareChange(false);

  const isJapan = locale === CULTURE_CODE.JAPANESE;
  const isStir = siteCode === SITECODE.STIR;
  const linkColor =
    isJapan || isStir ? SEMANTIC_COLOR_NAME.CORE1_100 : SEMANTIC_COLOR_NAME.CORE3_100;

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box marginBottom={SPACE.XLARGE}>
        <Checkbox
          id={checkboxId}
          checked={hasAccepted}
          value="hasAccepted"
          name="hasAccepted"
          onChange={handleAcceptedChange}
        >
          <Box marginLeft={SPACE.LARGE}>
            <Typography color={SEMANTIC_COLOR_NAME.CORE3_100}>
              <FormattedMessage
                {...messages.termsText}
                values={{
                  a: (...chunks: Array<string | React.ReactElement>) => (
                    <StyledClickHandler
                      innerRef={modalTriggerRef}
                      onClick={openTermsModal}
                      highlightedAnchorTag={isJapan}
                    >
                      <Typography color={linkColor}>{chunks}</Typography>
                    </StyledClickHandler>
                  ),
                  brand: brandName,
                }}
              />
            </Typography>
          </Box>
        </Checkbox>

        {hasAccepted && !isJapan && (
          <Box marginLeft={SPACE.XLARGE} paddingLeft={SPACE.XLARGE}>
            <Box marginTop={SPACE.SMALL}>
              <StyledRadio
                id={canShareFalseId}
                name="canShareFalse"
                onClick={handlePreventSharing}
                checked={!canShare}
              >
                <Box marginLeft={SPACE.LARGE}>
                  <StyledRadioText>
                    <FormattedMessage {...messages.cantShare} values={{ brand: brandName }} />
                  </StyledRadioText>
                </Box>
              </StyledRadio>
            </Box>
            <Box marginTop={SPACE.SMALL}>
              <StyledRadio id="canShareTrue" onClick={handleAllowSharing} checked={canShare}>
                <Box marginLeft={SPACE.LARGE} marginY={SPACE.MEDIUM}>
                  <StyledRadioText as="p">
                    <FormattedMessage {...messages.canShare} />
                  </StyledRadioText>
                  <StyledRadioText>
                    <FormattedMessage
                      {...messages.canShareMoreInfo}
                      values={{
                        a: (...chunks: Array<string | React.ReactElement>) => (
                          <ClickHandler onClick={openTermsModal}>
                            <Typography color={SEMANTIC_COLOR_NAME.CORE3_100}>{chunks}</Typography>
                          </ClickHandler>
                        ),
                        brand: brandName,
                      }}
                    />
                  </StyledRadioText>
                </Box>
              </StyledRadio>
            </Box>
          </Box>
        )}
      </Box>
      <TermsModal
        triggerElemRef={modalTriggerRef}
        isOpen={showTermsModal}
        onClose={closeTermsModal}
      />
    </Box>
  );
};

const StyledRadio = styled(Radio)({
  alignItems: 'baseline',
});

const StyledRadioText = styled(Typography)(({ theme }) => ({
  position: 'relative',
  bottom: theme.space.small,
  color: theme.palette.core3_100,
}));

interface StyledClickHandler {
  highlightedAnchorTag: boolean;
}

const StyledClickHandler = styled(ClickHandler)<{ highlightedAnchorTag: boolean }>(
  ({ highlightedAnchorTag, theme }) => ({
    textDecoration: highlightedAnchorTag ? 'underline' : 'none',
    textDecorationColor: highlightedAnchorTag ? theme.palette.core1_100 : theme.palette.core3_100,
  })
);

export default TermsSection;

const messages = defineMessages({
  termsText: {
    id: 'src/modules/components/StoryForm/components/TermsSection/TermsSection:termsText',
    description: 'description for checkbox to agree to the terms of use',
    defaultMessage: 'I agree with the <a>{brand} Success Terms of Use</a>',
  },
  termsLink: {
    id: 'src/modules/components/StoryForm/components/TermsSection/TermsSection:termsLink',
    description:
      'partial text for terms of use checkbox part 2, full text with desired text in quotes -> I agree with the "{brand} Success Terms of Use"',
    defaultMessage: '{brand} Success Terms of Use',
  },
  cantShare: {
    id: 'src/modules/components/StoryForm/components/TermsSection/TermsSection:cantShare',
    description: 'option to only share story with {brand} (no social media)',
    defaultMessage: 'Share with the {brand} team only',
  },
  canShare: {
    id: 'src/modules/components/StoryForm/components/TermsSection/TermsSection:canShare',
    description: 'option to opt in and allow for story to be shared on social media and elsewhere',
    defaultMessage: 'Share in Social Media and Elsewhere',
  },
  canShareMoreInfo: {
    id: 'src/modules/components/StoryForm/components/TermsSection/TermsSection:canShareMoreInfo',
    description: 'option to opt in and allow for story to be shared on social media and elsewhere',
    defaultMessage: '(See <a>{brand} Success Terms</a> for Full Details)',
  },
});
