import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, Button, constants, Typography } from '@match/react-component-library';
import { CULTURE_CODE, SITECODE } from '@match/app-rules-resolver';
import styled from 'packages/theming';
import { useLocale, useSiteCode } from 'modules/hooks';
import { ContentBox } from 'modules/components/ContentBox';
import { MEDIA_QUERY } from 'modules/constants/media-queries';

const { SEMANTIC_COLOR_NAME, SPACE, FONT_WEIGHT, FONT_SIZE, LINE_HEIGHTS, FONT_FACE } = constants;

const Confirmation = () => {
  const { locale } = useLocale();
  const { siteCode } = useSiteCode();

  const isStir = siteCode === SITECODE.STIR;
  const isJapan = locale === CULTURE_CODE.JAPANESE;

  return (
    <ConfirmationContentBox
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      marginRight={SPACE.XLARGE}
      marginTop={SPACE.XLARGE}
      maxWidth={648}
      minHeight={386}
      position="relative"
      width="100%"
    >
      <ConfirmationCopyBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        position="absolute"
        top={SPACE.XXXLARGE}
        paddingTop={SPACE.XXXLARGE}
        width="90%"
      >
        <Box marginY={SPACE.MEDIUM}>
          <Typography
            as="h2"
            fontWeight={FONT_WEIGHT.BOLD}
            fontSize={FONT_SIZE.XLARGE}
            fontFamily={FONT_FACE.HEADING}
            textAlign="center"
          >
            <FormattedMessage {...messages.confirmationHeader} />
          </Typography>
        </Box>
        <Box maxWidth={440} marginY={SPACE.MEDIUM}>
          <Typography
            as="p"
            fontSize={FONT_SIZE.XSMALL}
            lineHeight={LINE_HEIGHTS.SMALL}
            textAlign="center"
          >
            <FormattedMessage {...messages.confirmationBody} />{' '}
            {!isStir && <FormattedMessage {...messages.confirmationBodyLineTwo} />}
          </Typography>
        </Box>
        {!isJapan && !isStir && (
          <Box display="flex" justifyContent="center" marginY={SPACE.MEDIUM}>
            <a href="http://blog.match.com/">
              <Button size="large" color={SEMANTIC_COLOR_NAME.CORE3_100}>
                <FormattedMessage {...messages.confirmationButton} />
              </Button>
            </a>
          </Box>
        )}
      </ConfirmationCopyBox>
    </ConfirmationContentBox>
  );
};

const ConfirmationContentBox = styled(ContentBox)({
  [MEDIA_QUERY.TABLET]: {
    marginRight: SPACE.NONE,
  },
  [MEDIA_QUERY.MOBILE]: {
    minHeight: 600,
  },
});

const ConfirmationCopyBox = styled(Box)({
  [MEDIA_QUERY.MOBILE]: {
    paddingTop: SPACE.NONE,
  },
});

export default Confirmation;

const messages = defineMessages({
  confirmationHeader: {
    id: 'src/modules/components/Confirmation/Confirmation:confirmationHeader',
    description: 'confirmation screen headline',
    defaultMessage: 'Thank you for submitting your story.',
  },
  confirmationBody: {
    id: 'src/modules/components/Confirmation/Confirmation:confirmationBody',
    description: 'confirmation screen body content',
    defaultMessage: 'We look forward to reading it.',
  },
  confirmationBodyLineTwo: {
    id: 'src/modules/components/Confirmation/Confirmation:confirmationBodyLineTwo',
    description: 'confirmation screen body content',
    defaultMessage:
      ' Be sure to check out some of the other inspiring stories featured on our blog.',
  },
  confirmationButton: {
    id: 'src/modules/components/Confirmation/Confirmation:confirmationButton',
    description: 'button to visit blog',
    defaultMessage: 'Visit our blog',
  },
  successCoupleAlt: {
    id: 'src/modules/components/Confirmation/Confirmation:successCoupleAlt',
    description: 'photo of a success story couple that met on Match',
    defaultMessage: 'successful couples that met on Match.com',
  },
});
