import React, { useState } from 'react';
import { Alert, Box, BoxProps } from '@match/react-component-library';
import { CULTURE_CODE, SITECODE, SiteCode } from '@match/app-rules-resolver';
import { Confirmation, Footer, Header, RightRail, StoryForm } from 'modules/components';
import { useBrandName, useLocale, useSiteCode } from 'modules/hooks';

import { MEDIA_QUERY } from 'modules/constants/media-queries';
import { OneTrust } from 'modules/components';
import SplashFactory from 'modules/components/Splash/SplashFactory';
import styled from 'packages/theming';

const MAX_CONTENT_WIDTH = 1068;
const MAX_SPLASH_WIDTH = 991;
const MAX_STIR_SPLASH_WIDTH = 1140;

const SuccessStoryPage = () => {
  const { locale } = useLocale();
  const { siteCode } = useSiteCode();
  const { brandName } = useBrandName();
  const isStir = siteCode === SITECODE.STIR;
  const [error, setError] = useState('');
  const [showStoryForm, setShowStoryForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const showSocialMedia = locale !== CULTURE_CODE.JAPANESE;
  const showGiftCardLegal = locale === CULTURE_CODE.JAPANESE;

  const handleReturnToSplash = () => {
    setShowStoryForm(false);
    setShowConfirmation(false);
  };

  const handleStoryFormSubmit = () => {
    setShowStoryForm(false);
    setShowConfirmation(true);
  };

  const clearError = () => setError('');

  const showSplash = !showStoryForm && !showConfirmation;
  const showRightRail = showStoryForm || showConfirmation;

  let mainContainerMaxWidth = MAX_CONTENT_WIDTH;
  if (!showStoryForm) {
    mainContainerMaxWidth = isStir ? MAX_STIR_SPLASH_WIDTH : MAX_SPLASH_WIDTH;
  }

  return (
    <MainDivContainerBox siteCode={siteCode}>
      <OneTrust />
      <Header onLogoClick={handleReturnToSplash} />
      <BodyContainerBox
        maxWidth={mainContainerMaxWidth}
        justifyContent={showStoryForm ? 'space-between' : 'center'}
      >
        {!!error && (
          <Box width="100%">
            <Alert type="error" onClose={clearError} delay={10000} closable>
              {error}
            </Alert>
          </Box>
        )}
        {showSplash && <SplashFactory onButtonClick={() => setShowStoryForm(true)} />}
        {showStoryForm && (
          <StoryForm
            onSubmit={handleStoryFormSubmit}
            onBack={handleReturnToSplash}
            onError={setError}
          />
        )}
        {showConfirmation && <Confirmation />}
        {showRightRail && (
          <RightRail
            showGiftCardLegal={showGiftCardLegal}
            showSocialMedia={showSocialMedia}
            showTips={showStoryForm}
            brandName={brandName}
          />
        )}
      </BodyContainerBox>
      <Footer />
    </MainDivContainerBox>
  );
};

const BodyContainerBox = styled(Box)(({ theme }) => ({
  minHeight: '818px',
  width: '100%',
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  alignContent: 'baseline',
  marginTop: theme.space.none,
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    justifyContent: 'center',
    minHeight: 'unset',
  },
  [MEDIA_QUERY.TABLET]: {
    padding: `${theme.space.none}px ${theme.space.xlarge}px`,
    justifyContent: 'center',
  },
}));

interface MainDivContainerBoxProps extends BoxProps {
  siteCode: SiteCode;
}

const MainDivContainerBox = styled(Box)<MainDivContainerBoxProps>(({ siteCode, theme }) => ({
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.core3_100,
  background:
    siteCode === SITECODE.STIR
      ? theme.palette.core5_100
      : 'linear-gradient(148deg,#fdf4e5 47.71%,#979ad8 135.84%);',
}));

export default SuccessStoryPage;
