import axios from 'axios';
import { CultureCode, CULTURE_CODE } from '@match/app-rules-resolver';
import {
  ElasticSearchLocationResponse,
  PostPhotosData,
  PostUserData,
  SiteDataResponse,
} from 'types';

const API_URL = `${process.env.PUBLIC_URL}/api`;
const ELASTIC_SEARCH_LIMIT = 5;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

// Request middleware
axiosInstance.interceptors.request.use(
  (config) => {
    // Add something to each request
    return config;
  },
  (error) => {
    // Do something for each request error.
    return Promise.reject(error);
  }
);

// Targeting criteria response middleware
axiosInstance.interceptors.response.use(
  (response) => {
    // Do something after each successful response
    return response;
  },
  (error) => {
    // Do something for each api error.
    // e.g. redirect 401 responses to login page
    return Promise.reject(error);
  }
);

const request = (config = {}) => axiosInstance(config);

export default {
  elasticSearchLocation: (q: string, cultureCode: CultureCode) => {
    const data = {
      q,
      cultureCode,
      size: ELASTIC_SEARCH_LIMIT,
      restrictions: cultureCode === CULTURE_CODE.ENGLISH ? [1] : undefined,
    };

    return axios({
      url: 'https://suggest.services.match.com/es/search/regions',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data,
    }).then((res) => res as ElasticSearchLocationResponse);
  },
  fetchSiteData: () => {
    return request({
      url: '/sitedata',
      method: 'GET',
    }).then((res) => res as SiteDataResponse);
  },
  createPhotos: (data: PostPhotosData) => {
    return request({
      url: '/photos',
      method: 'POST',
      data,
    }).then((res) => res.data as string[]);
  },
  createUser: (data: PostUserData) => {
    return request({
      url: '/users',
      method: 'POST',
      data,
    });
  },
};
