import { Box, Button, ButtonProps, Typography, constants } from '@match/react-component-library';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { MEDIA_QUERY } from 'modules/constants/media-queries';
import React from 'react';
import splashPhotoDesktop from './stir-splash-photo-desktop.jpg';
import splashPhotoMobi from './stir-splash-photo-mobi.jpg';
import styled from 'packages/theming';

const { SEMANTIC_COLOR_NAME } = constants;

interface SplashProps {
  onButtonClick: () => void;
}

const StirSplash = ({ onButtonClick }: SplashProps) => {
  const intl = useIntl();

  return (
    <SplashContainerBox>
      <StyledSplashHeader color={SEMANTIC_COLOR_NAME.DARK}>
        <FormattedMessage {...messages.header} />
      </StyledSplashHeader>
      <StyledSubtext color={SEMANTIC_COLOR_NAME.DARK}>
        <FormattedMessage {...messages.subtext} />
      </StyledSubtext>
      <StyledSubmitButton
        color={SEMANTIC_COLOR_NAME.CORE1_100}
        fontColor={SEMANTIC_COLOR_NAME.CORE5_100}
        onClick={onButtonClick}
        size="xlarge"
      >
        <FormattedMessage {...messages.button} />
      </StyledSubmitButton>
      <StyledPrimaryImg
        desktopSrc={splashPhotoDesktop}
        mobiSrc={splashPhotoMobi}
        alt={intl.formatMessage(messages.successPhotoAlt)}
      />
      <StyledInspirationHeading color={SEMANTIC_COLOR_NAME.DARK}>
        <FormattedMessage {...messages.inspirationHeading} />
      </StyledInspirationHeading>
      <StyledInspirationContainer>
        <StyledInspirationBox>
          <Typography fontSize={24} fontWeight={'semibold'}>
            <FormattedMessage {...messages.inspirationExampleTitle1} />
          </Typography>
          <Typography fontSize={14} lineHeight="20px">
            <FormattedMessage {...messages.inspirationExampleBody1} />
          </Typography>
        </StyledInspirationBox>
        <StyledInspirationBox>
          <Typography fontSize={24} fontWeight={'semibold'}>
            <FormattedMessage {...messages.inspirationExampleTitle2} />
          </Typography>
          <Typography fontSize={14} lineHeight="20px">
            <FormattedMessage {...messages.inspirationExampleBody2} />
          </Typography>
        </StyledInspirationBox>
      </StyledInspirationContainer>
      <Box paddingTop="32px">
        <StyledSubmitButton
          onClick={onButtonClick}
          color={SEMANTIC_COLOR_NAME.CORE1_100}
          fontColor={SEMANTIC_COLOR_NAME.CORE5_100}
          size="xlarge"
          mobileOnly
        >
          <FormattedMessage {...messages.button} />
        </StyledSubmitButton>
      </Box>
    </SplashContainerBox>
  );
};

interface StyledPrimaryImg {
  desktopSrc: string;
  mobiSrc: string;
}

const StyledPrimaryImg = styled('img')(({ desktopSrc, mobiSrc }: StyledPrimaryImg) => ({
  width: '100%',
  borderRadius: '16px',
  content: `url(${desktopSrc})`,
  marginBottom: '40px',
  [MEDIA_QUERY.MOBILE]: {
    flexDirection: 'column',
    content: `url(${mobiSrc})`,
  },
}));

interface StyledSubmitButton extends ButtonProps {
  mobileOnly?: boolean;
}

const StyledSubmitButton = styled(Button)<StyledSubmitButton>(({ mobileOnly }) => ({
  marginBottom: 40,
  fontSize: 16,
  width: 183,
  display: mobileOnly ? 'none' : 'inherit',
  [MEDIA_QUERY.MOBILE]: {
    display: 'inherit',
  },
}));

const StyledInspirationBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.feature1_10,
  borderRadius: '16px',
  width: '50%',
  gap: '16px',
  padding: '40px 60px 0 60px',
  height: '166px',
  textAlign: 'center',
  color: theme.palette.dark,
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    padding: '30px 60px 0 60px',
  },
  [MEDIA_QUERY.TABLET]: {
    padding: '30px 35px 0 35px',
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '40px 16px',
    width: '100%',
    height: 'unset',
  },
}));

const StyledInspirationContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 20,
  [MEDIA_QUERY.MOBILE]: {
    flexDirection: 'column',
  },
});

const SplashContainerBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '80px',
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    padding: '56px 24px 0 24px',
  },
  [MEDIA_QUERY.TABLET]: {
    padding: '56px 0 0 0',
  },
});

const StyledSplashHeader = styled(Typography)({
  fontSize: 28,
  fontWeight: 600,
  marginBottom: 24,
  textAlign: 'center',
});

const StyledSubtext = styled(Typography)({
  fontSize: 16,
  marginBottom: 32,
  textAlign: 'center',
});

const StyledInspirationHeading = styled(Typography)({
  fontSize: 24,
  marginBottom: 40,
  fontWeight: 600,
  textAlign: 'center',
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 16px 40px 16px',
  },
});

export default StirSplash;

const messages = defineMessages({
  header: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:header',
    description: 'splash page headline',
    defaultMessage: 'Have you found your match on Stir?',
  },
  subtext: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:subtext',
    description: 'splash page subtext',
    defaultMessage: 'Check out these Stir community stories & share yours!',
  },
  button: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:button',
    description: 'button to submit story',
    defaultMessage: 'Submit your story',
  },
  successPhotoAlt: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:successPhotoAlt',
    description: 'photo of success story couple',
    defaultMessage: 'Success Couple',
  },
  inspirationHeading: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:inspirationHeading',
    description: 'splash page inspiration heading text',
    defaultMessage: 'Here are a few examples for inspiration.',
  },
  inspirationExampleTitle1: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:inspirationExampleTitle1',
    description: 'splash page inspiration example title text',
    defaultMessage: 'Made for each other',
  },
  inspirationExampleTitle2: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:inspirationExampleTitle2',
    description: 'splash page inspiration example title text',
    defaultMessage: 'Someone who gets it',
  },
  inspirationExampleBody1: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:inspirationExampleBody1',
    description: 'splash page inspiration example body text',
    defaultMessage: 'We matched about 6 months ago and now we spend every other weekend together!',
  },
  inspirationExampleBody2: {
    id: 'src/modules/components/Splash/components/StirSplash/StirSplash:inspirationExampleBody2',
    description: 'splash page inspiration example body text',
    defaultMessage:
      "From the time we connected on Stir, our bond was instant. It's great to meet someone who gets what being a single parent is like!",
  },
});
