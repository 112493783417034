import React from 'react';
import { Box, constants, Logo, Text } from '@match/react-component-library';
import { SITECODE } from '@match/app-rules-resolver';
import { SC3AdditionalFooterLinks } from './SC3AdditionalFooterLinks';
import styled from 'packages/theming';
import { useSiteCode } from 'modules/hooks';
import { MEDIA_QUERY } from 'modules/constants/media-queries';
import { defineMessages, FormattedMessage } from 'react-intl';

const { SPACE, SEMANTIC_COLOR_NAME } = constants;

const MAX_HEADER_WIDTH = 1156;

const { siteCode } = useSiteCode();
const isStir = siteCode === SITECODE.STIR;
const siteUrl = isStir ? 'https://www.stir.com' : 'https://www.match.com';
const siteAria = isStir ? 'stir.com' : 'match.com';

const Footer = () => {
  return (
    <StyledFooterBox width="100%" paddingX={SPACE.XLARGE}>
      <Box>
        <StyledLogoBox alignItems='center'>
          <a href={siteUrl} target="_blank">
            <Logo
              size="abbreviated"
              width={60}
              height={55}
              color={SEMANTIC_COLOR_NAME.CORE3_100}
              aria-hidden
            />
            <span aria-label={siteAria} />
          </a>
          <Box paddingX="small">
            <Text typography='largeBody'>
              <FormattedMessage {...messages.tradeMark} />&#8482;
            </Text>
          </Box>
          <SC3AdditionalFooterLinks />
        </StyledLogoBox>
      </Box>
    </StyledFooterBox>
  );
};

const StyledFooterBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: SPACE.XXXLARGE,
  marginX: 'auto',
  maxWidth: MAX_HEADER_WIDTH,
  minHeight: 56,
  position: 'relative',
  width: '100%',
  margin: `${theme.space.xlarge}px ${theme.space.none}px`,
  [MEDIA_QUERY.MOBILE]: {
    marginTop: theme.space.none,
    marginBottom: theme.space.xxxlarge,
    justifyContent: 'center',
  },
}));

const StyledLogoBox = styled(Box)({
  left: SPACE.NONE,
  width: '100%',
  display: 'flex',
  [MEDIA_QUERY.MOBILE]: {
    justifyContent: 'center',
  },
});

const messages = defineMessages({
  tradeMark: {
    id: 'src/modules/components/Footer/Footer:tradeMark',
    description: 'trade mark Match Stories',
    defaultMessage: 'Match Stories',
  },
});

export default Footer;
