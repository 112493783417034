import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, constants, Typography } from '@match/react-component-library';
import { SITECODE } from '@match/app-rules-resolver';
import styled from 'packages/theming';
import { useSiteCode } from 'modules/hooks';
import { ContentBox } from 'modules/components/ContentBox';
import { SC3GiftCardLegal, Tip } from './components';
import { MEDIA_QUERY } from 'modules/constants/media-queries';
import instagramSrc from './social-instagram.png';
import facebookSrc from './social-facebook.png';
import twitterSrc from './social-twitter.png';
import tiktokSrc from './social-tiktok.png';
import { SEMANTIC_COLOR_NAME } from '@match/react-component-library/components/theme/constants';

const { FONT_SIZE, FONT_WEIGHT, LINE_HEIGHTS, SPACE, FONT_FACE } = constants;

interface RightRailProps {
  showGiftCardLegal: boolean;
  showSocialMedia: boolean;
  showTips: boolean;
  brandName: string;
}

const RightRail = ({ showGiftCardLegal, showSocialMedia, showTips, brandName }: RightRailProps) => {
  const { siteCode } = useSiteCode();
  const isStir = siteCode === SITECODE.STIR;
  const instagramUrl = isStir
    ? 'https://www.instagram.com/stir.app'
    : 'https://www.instagram.com/match';

  return (
    <RightRailContainerBox display="inline-block" maxWidth={348} width={348} minWidth={200}>
      {showSocialMedia && (
        <ContentBox display="flex" flex={1} flexDirection="column" marginTop={SPACE.XLARGE}>
          <Typography
            as="h1"
            fontSize={FONT_SIZE.LARGE}
            fontWeight={FONT_WEIGHT.BOLD}
            fontFamily={FONT_FACE.HEADING}
            color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : undefined}
          >
            <FormattedMessage {...messages.checkUsOut} />
          </Typography>
          <Box marginY={SPACE.XLARGE}>
            <Typography
              fontSize={FONT_SIZE.XSMALL}
              lineHeight={LINE_HEIGHTS.XSMALL}
              fontFamily={FONT_FACE.BODY}
              color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : undefined}
            >
              <FormattedMessage {...messages.followUs} />
            </Typography>
          </Box>
          <Box display="flex">
            <Box marginRight={SPACE.XLARGE}>
              <a href={instagramUrl} target="_blank">
                <div>
                  <img height={24} src={instagramSrc} alt="Instagram" />
                </div>
              </a>
            </Box>
            {!isStir && (
              <Box marginRight={SPACE.XLARGE}>
                <a href="https://facebook.com/match" target="_blank">
                  <Box width={24} display="flex" justifyContent="center">
                    <img height={24} src={facebookSrc} alt="Facebook" />
                  </Box>
                </a>
              </Box>
            )}
            {!isStir && (
              <Box>
                <a href="https://twitter.com/Match" target="_blank">
                  <div>
                    <img height={24} src={twitterSrc} alt="Twitter" />
                  </div>
                </a>
              </Box>
            )}
            {isStir && (
              <Box>
                <a href="https://www.tiktok.com/@stirdatingapp" target="_blank">
                  <div>
                    <img height={24} src={tiktokSrc} alt="TikTok" />
                  </div>
                </a>
              </Box>
            )}
          </Box>
        </ContentBox>
      )}
      {showTips && (
        <ContentBox display="flex" flex={1} flexDirection="column" marginTop={SPACE.XLARGE}>
          <Typography
            id="stuckOnWhatToWrite"
            fontSize={FONT_SIZE.LARGE}
            fontWeight={FONT_WEIGHT.BOLD}
            fontFamily={FONT_FACE.HEADING}
            color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : undefined}
          >
            <FormattedMessage {...messages.stuckHeader} />
          </Typography>
          <Tip>
            <FormattedMessage {...messages.stuckTip1} values={{ brand: brandName }} />
          </Tip>
          <Tip>
            <FormattedMessage {...messages.stuckTip2} />
          </Tip>
          <Tip>
            <FormattedMessage {...messages.stuckTip3} />
          </Tip>
          <Tip>
            <FormattedMessage {...messages.stuckTip4} />
          </Tip>
          <Tip>
            <FormattedMessage {...messages.stuckTip5} />
          </Tip>
          <Tip>
            <FormattedMessage {...messages.stuckTip6} />
          </Tip>
        </ContentBox>
      )}
      {showGiftCardLegal && (
        <ContentBox
          display="flex"
          id="giftCardDetails"
          flex={1}
          flexDirection="column"
          marginTop={SPACE.XLARGE}
        >
          <SC3GiftCardLegal />
        </ContentBox>
      )}
    </RightRailContainerBox>
  );
};

const RightRailContainerBox = styled(Box)({
  [MEDIA_QUERY.TABLET]: {
    width: '100%',
    maxWidth: '100%',
  },
  [MEDIA_QUERY.MOBILE]: {
    maxWidth: 440,
  },
});

export default RightRail;

const messages = defineMessages({
  checkUsOut: {
    id: 'src/modules/components/RightRail/RightRail:checkUsOut',
    description: 'social media links header',
    defaultMessage: 'Check us out!',
  },
  followUs: {
    id: 'src/modules/components/RightRail/RightRail:followUs',
    description: 'social media links body',
    defaultMessage: 'Follow us to see some of the success stories shared with us.',
  },
  stuckHeader: {
    id: 'src/modules/components/RightRail/RightRail:stuckHeader',
    description: 'essay tips header',
    defaultMessage: 'Stuck on what to write?',
  },
  stuckTip1: {
    id: 'src/modules/components/RightRail/RightRail:stuckTip1',
    description: 'essay tip',
    defaultMessage: 'Start with how you met on {brand}',
  },
  stuckTip2: {
    id: 'src/modules/components/RightRail/RightRail:stuckTip2',
    description: 'essay tip',
    defaultMessage: 'Tell us about your first date',
  },
  stuckTip3: {
    id: 'src/modules/components/RightRail/RightRail:stuckTip3',
    description: 'essay tip',
    defaultMessage: 'When you knew they were the one',
  },
  stuckTip4: {
    id: 'src/modules/components/RightRail/RightRail:stuckTip4',
    description: 'essay tip',
    defaultMessage: 'What you love about each other',
  },
  stuckTip5: {
    id: 'src/modules/components/RightRail/RightRail:stuckTip5',
    description: 'essay tip',
    defaultMessage: 'Most memorable moments',
  },
  stuckTip6: {
    id: 'src/modules/components/RightRail/RightRail:stuckTip6',
    description: 'essay tip',
    defaultMessage: 'Challenges you overcame',
  },
});
