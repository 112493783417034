import SITE_NAME from 'modules/constants/site-name';
import SITE from '../constants/sites';

export const useBrandName = () => {
  //TODO This is based off of the hostname from the URL, but ideally we'd like to refactor to a sitecode-based implementation
  const location = window.location.href;

  let brandName: string = SITE_NAME.MATCH;

 if (location.includes(SITE.STIR)) {
    brandName = SITE_NAME.STIR;
  }

  return { brandName };
};
