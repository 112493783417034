import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ClickHandler, Box, constants, Icon, Image } from '@match/react-component-library';

const { ICON_NAMES, RADII, SEMANTIC_COLOR_NAME, SPACE } = constants;

export type ClickHandlerEvent =
  | React.MouseEvent<Element, MouseEvent>
  | React.KeyboardEvent<Element>
  | undefined;

interface PhotoPreviewProps {
  path: string;
  preview?: string;
  onClick: () => void;
}

const PhotoPreview = ({ path, preview, onClick }: PhotoPreviewProps) => {
  const intl = useIntl();

  const handleClick = (e?: ClickHandlerEvent) => {
    if (!e) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();

    onClick();
  };

  return (
    <Box height={64} key={path} marginRight={SPACE.MEDIUM} marginBottom={SPACE.MEDIUM}>
      <Image
        shape={RADII.ROUND_CORNER_1X}
        width={64}
        height={64}
        src={preview}
        alt={intl.formatMessage(messages.previewAlt)}
      >
        <ClickHandler style={{ position: 'absolute', right: 0 }} onClick={handleClick}>
          <Box
            center
            position="absolute"
            top={SPACE.XSMALL}
            right={SPACE.XSMALL}
            backgroundColor={SEMANTIC_COLOR_NAME.LIGHT}
            borderRadius={RADII.ROUND}
            padding={SPACE.XSMALL}
          >
            <Icon
              size={SPACE.XSMALL}
              name={ICON_NAMES.close}
              color={SEMANTIC_COLOR_NAME.CTA_PRIMARY}
            />
          </Box>
        </ClickHandler>
      </Image>
    </Box>
  );
};

export default PhotoPreview;

const messages = defineMessages({
  previewAlt: {
    id:
      'src/modules/components/StoryForm/components/PhotoUploader/components/PhotoPreview/PhotoPreview:previewAlt',
    description: "Preview of user's uploaded photo",
    defaultMessage: 'preview of uploaded photo',
  },
});
