import React from 'react';
import { Box, Typography, constants } from '@match/react-component-library';
import { CULTURE_CODE } from '@match/app-rules-resolver';
import styled from 'packages/theming';
import { MEDIA_QUERY } from 'modules/constants/media-queries';

const { FONT_WEIGHT, SPACE } = constants;

interface QuoteProps {
  children: React.ReactNode;
  couplesName?: string;
  locale: string;
}

const Quote = ({ children, couplesName, locale }: QuoteProps) => {
  if (locale === CULTURE_CODE.ENGLISH) {
    return (
      <StyledBox width="100%">
        <StyledQuoteSpan as="span">“</StyledQuoteSpan>
        <StyledText>{children}</StyledText>
        <Box marginTop={SPACE.XSMALL}>
          <CoupleNameText as="p">{couplesName}</CoupleNameText>
        </Box>
      </StyledBox>
    );
  } else {
    return (
      <StyledBox width="100%">
        <StyledQuoteSpan as="span">“</StyledQuoteSpan>
        <StyledText>{children}</StyledText>
      </StyledBox>
    );
  }
};

const StyledBox = styled(Box)(({ theme }) => ({
  fontStyle: 'normal',
  lineHeight: theme.lineHeights.medium,
  opacity: 0.95,
  [MEDIA_QUERY.TABLET]: {
    lineHeight: theme.lineHeights.small,
  },
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: theme.lineHeights.small,
  },
}));

const StyledText = styled(Typography)(({ theme }) => ({
  margin: theme.space.none,
  color: theme.palette.core3_75,
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.medium,
  [MEDIA_QUERY.TABLET]: {
    fontSize: theme.fontSizes.xsmall,
    lineHeight: theme.lineHeights.small,
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: theme.fontSizes.small,
    lineHeight: theme.lineHeights.small,
  },
}));

const StyledQuoteSpan = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '88px',
  left: '-11px',
  top: '35px',
  fontFamily: 'Reckless Neue',
  fontSize: 200,
  letterSpacing: -1,
  color: theme.palette.core3_100,
  opacity: 0.1,
}));

const CoupleNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.core3_75,
  fontSize: theme.fontSizes.xsmall,
  fontWeight: FONT_WEIGHT.BOLD,
  [MEDIA_QUERY.TABLET]: {
    fontSize: theme.fontSizes.xxsmall,
  },
  [MEDIA_QUERY.MOBILE]: {
    fontsize: theme.fontSizes.xxsmall,
  },
}));

export default Quote;
