import React from 'react';
import { Box, constants, Typography } from '@match/react-component-library';
import { COLOR } from 'modules/constants/color';
import { useSiteCode } from 'modules/hooks';
import { SITECODE } from '@match/app-rules-resolver';

const { SPACE, SEMANTIC_COLOR_NAME, FONT_SIZE, FONT_WEIGHT, FONT_FACE } = constants;

interface StoryFormSection {
  children: React.ReactNode;
  noBottomBorder?: boolean;
  required?: boolean;
  subtitle?: string | React.ReactNode;
  title?: string;
}

const StoryFormSection = ({
  children,
  noBottomBorder,
  required,
  subtitle,
  title,
}: StoryFormSection) => {
  const { siteCode } = useSiteCode();
  const isStir = siteCode === SITECODE.STIR;

  return (
    <Box
      as="section"
      borderBottom={noBottomBorder ? undefined : `1px solid ${COLOR.GRAY}`}
      display="flex"
      flexDirection="column"
      marginBottom={SPACE.MEDIUM}
      marginTop={SPACE.XLARGE}
      paddingBottom={SPACE.MEDIUM}
      width="100%"
    >
      {(title || subtitle) && (
        <Box display="flex" flexDirection="column" marginBottom={SPACE.XLARGE}>
          {title && (
            <Box marginY={SPACE.SMEDIUM}>
              <Typography
                as="h2"
                fontWeight={FONT_WEIGHT.BOLD}
                fontSize={FONT_SIZE.SMALL}
                color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : SEMANTIC_COLOR_NAME.CORE3_100}
                fontFamily={FONT_FACE.HEADING}
              >
                {title}
              </Typography>
            </Box>
          )}
          {subtitle && (
            <Box marginTop={SPACE.SMALL}>
              <Typography fontSize={FONT_SIZE.XSMALL}>
                {subtitle}
                {!!required && ' *'}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default StoryFormSection;
