export const useParseDate = (dateFormat: string) => (value: string, prev: string) => {
  if (value.length < prev.length) {
    return value;
  }

  const trimmedVal = value.trim();
  const firstMax = dateFormat.match(/^YYYY/) ? 4 : 2;
  const lastMax = dateFormat.match(/^YYYY/) ? 2 : 4;

  const addSlashRegex = new RegExp(`^\\d{${firstMax}}$|^\\d{1,${firstMax}}/\\d{2}$`);
  if (trimmedVal.match(addSlashRegex)) {
    return value + '/';
  }

  const slotFirstSlashRegex = new RegExp(`^\\d{${firstMax + 1}}$`);
  if (trimmedVal.match(slotFirstSlashRegex)) {
    return value.slice(0, value.length - 1) + '/' + value[value.length - 1];
  }

  const slotSecondSlashRegex = new RegExp(`^\\d{${firstMax}}/\\d{3}$`);
  if (trimmedVal.match(slotSecondSlashRegex)) {
    return value.slice(0, value.length - 1) + '/' + value[value.length - 1];
  }

  const validRegex = new RegExp(
    `^\\d{1,${firstMax}}/?$|^\\d{1,${firstMax}}/\\d{1,2}/?$|^\\d{1,${firstMax}}/\\d{1,2}/\\d{1,${lastMax}}$|^$`
  );
  if (trimmedVal.match(validRegex)) {
    return value;
  }

  return prev;
};

// TODO add some testing
