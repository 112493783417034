import { Box } from '@match/react-component-library';
import styled from 'packages/theming';
import { COLOR } from 'modules/constants/color';

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.space.xlarge,
  border: `solid 1px ${COLOR.GRAY}`,
  borderRadius: theme.radii.roundCorner2x,
}));

export default ContentBox;
