import SITE from '../constants/sites';
import { CultureCode, CULTURE_CODE } from '@match/app-rules-resolver';

export const useLocale = () => {
  const browserLang = navigator.language;
  const location = window.location.href;

  let locale: CultureCode = CULTURE_CODE.ENGLISH;

  if (location.includes(SITE.JAPAN) || browserLang === 'ja' || browserLang === 'ja-JP') {
    locale = CULTURE_CODE.JAPANESE;
  } else if (
    location.includes(SITE.PORTUGUESE) ||
    browserLang === 'pt' ||
    browserLang === 'pt-BR'
  ) {
    locale = CULTURE_CODE.PORTUGUESE;
  } else if (
    location.includes(SITE.SPANISH) ||
    browserLang === 'es' ||
    browserLang === 'es-MX' ||
    browserLang === 'es-AR'
  ) {
    locale = CULTURE_CODE.SPANISH_MEXICO;
  }

  return { locale };
};
