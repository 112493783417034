import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, Button, constants, Typography, Heading } from '@match/react-component-library';
import { CULTURE_CODE } from '@match/app-rules-resolver';
import styled from 'packages/theming';
import { SplashPhotos } from './components';
import { useBrandName, useLocale } from 'modules/hooks';
import { MEDIA_QUERY } from 'modules/constants/media-queries';
import { TYPOGRAPHY } from '@match/react-component-library/components/theme/constants';

const { SEMANTIC_COLOR_NAME, SPACE } = constants;
const { locale } = useLocale();

interface SplashProps {
  onButtonClick: () => void;
}

const Splash = ({ onButtonClick }: SplashProps) => {
  const { brandName } = useBrandName();
  const japaneseSplashBoldedText = (
    <strong>
      <FormattedMessage {...messages.japaneseSplashBoldedText} values={{ brandName }} />
    </strong>
  );
  return (
    <SplashContainerBox>
      <SplashMainContentBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        maxWidth={386}
        paddingRight={SPACE.MEDIUM}
      >
        <Box
          marginBottom={SPACE.XXXLARGE}
          paddingBottom={SPACE.LARGE}
          display="flex"
          flexDirection="column"
          maxWidth={362}
        >
          <Box marginTop={SPACE.MEDIUM} marginBottom={SPACE.SMALL}>
            <StyledSplashHeader
              typography={TYPOGRAPHY.HEADING1}
              priority={1}
              largeHeading={locale !== CULTURE_CODE.JAPANESE}
            >
              <FormattedMessage {...messages.header} />
            </StyledSplashHeader>
          </Box>
          <Box marginTop={SPACE.XLARGE}>
            <StyledSplashParagraphBox>
              <SplashParagraphText>
                {locale === CULTURE_CODE.JAPANESE ? (
                  <FormattedMessage
                    {...messages.japaneseSplashText}
                    values={{ br: <br />, boldText: japaneseSplashBoldedText, brandName }}
                  />
                ) : (
                  <FormattedMessage {...messages.body} />
                )}
              </SplashParagraphText>
            </StyledSplashParagraphBox>
            <StyledShareButtonWrapper isJapan={locale === CULTURE_CODE.JAPANESE}>
              <Button
                onClick={onButtonClick}
                color={SEMANTIC_COLOR_NAME.CORE3_100}
                fontColor={SEMANTIC_COLOR_NAME.CORE5_100}
                size="xlarge"
              >
                <FormattedMessage {...messages.button} />
              </Button>
            </StyledShareButtonWrapper>
          </Box>
        </Box>
      </SplashMainContentBox>

      <Box flex={1} margin={SPACE.NONE} position="relative">
        <SplashPhotos />
      </Box>
    </SplashContainerBox>
  );
};

const SplashContainerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '56px',
  [MEDIA_QUERY.MOBILE]: {
    flexDirection: 'column',
    paddingTop: theme.space.none,
  },
}));

const SplashMainContentBox = styled(Box)({
  flex: '0 0 386px',
  [MEDIA_QUERY.TABLET]: {
    maxWidth: '100%',
    flexBasis: 'auto',
    width: '45%',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'block',
    width: '100%',
  },
});

interface StyledSplashHeader {
  largeHeading: boolean;
}

const StyledSplashHeader = styled(Heading)(({ largeHeading }: StyledSplashHeader) => ({
  fontSize: largeHeading ? 54 : 44,
  letterSpacing: -4,
  fontStyle: 'italic',
  [MEDIA_QUERY.TABLET]: {
    fontSize: 46,
  },
}));

const SplashParagraphText = styled(Typography)(({ theme }) => ({
  color: theme.palette.core3_100,
  fontSize: theme.fontSizes.large,
  [MEDIA_QUERY.TABLET]: {
    fontSize: theme.fontSizes.large,
  },
}));

const StyledSplashParagraphBox = styled(Box)({
  height: '180px',
  maxWidth: '362px',
  left: '249px',
  top: '357px',
});

const StyledShareButtonWrapper = styled(Box)<{ isJapan: boolean }>(({ isJapan, theme }) => ({
  left: '2px',
  top: '2px',
  marginTop: isJapan ? `${theme.space.none}px` : `${theme.space.medium}px`,
  marginBottom: theme.space.medium,
  padding: isJapan ? `${theme.space.none}px` : `${theme.space.xlarge}px`,
  textAlign: isJapan ? 'center' : 'initial',
  [MEDIA_QUERY.MOBILE]: {
    marginTop: isJapan ? `${theme.space.xlarge}px` : `${theme.space.medium}px`,
  },
  [MEDIA_QUERY.TABLET]: {
    position: 'relative',
    top: '-13px',
  },
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: theme.space.none,
  },
}));

export default Splash;

const messages = defineMessages({
  header: {
    id: 'src/modules/components/Splash/Splash:header',
    description: 'splash page headline',
    defaultMessage: 'Share your story',
  },
  body: {
    id: 'src/modules/components/Splash/Splash:body',
    description: 'splash page body content',
    defaultMessage:
      'We believe in love, and we believe it’s contagious. That’s why we’d love for you to share your story, along with any words of inspiration for others who are hoping to find love too!',
  },
  button: {
    id: 'src/modules/components/Splash/Splash:button',
    description: 'button to submit story',
    defaultMessage: 'Submit your story',
  },
  japaneseSplashText: {
    id: 'src/modules/components/Splash/Splash:japaneseSplashText',
    description: 'splash text for SC3',
    defaultMessage:
      'We are looking the stories of those who have been dating and enrolled in {brandName}! {br} {boldText}',
  },
  japaneseSplashBoldedText: {
    id: 'src/modules/components/Splash/Splash:japaneseSplashBoldedText',
    description: 'splash bolded text for SC3',
    defaultMessage: 'We will use the contents of the report for {brandName} PR.',
  },
  japaneseHighlightedSplashText: {
    id: 'src/modules/components/Splash/Splash:japaneseHighlightedSplashText',
    description: 'highlighted splash text for SC3',
    defaultMessage: 'All couples will receive a gift certificate worth 10,000 yen!',
  },
});
