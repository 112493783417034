import { defineMessages, IntlShape } from 'react-intl';

const relationshipStatusString = (relationshipStatusId: number, intl: IntlShape) => {
  let relationshipStatusName;
  switch (relationshipStatusId) {
    case 1:
      relationshipStatusName = messages.dating;
      break;
    case 2:
      relationshipStatusName = messages.engaged;
      break;
    case 3:
      relationshipStatusName = messages.married;
      break;
    case 4:
      relationshipStatusName = messages.other;
      break;
    default:
      relationshipStatusName = messages.dating;
  }
  return intl.formatMessage(relationshipStatusName);
};

export default relationshipStatusString;

const messages = defineMessages({
  dating: {
    id: 'src/utils/relationship-status:dating',
    description: 'relationship status dating option',
    defaultMessage: 'Dating',
  },
  engaged: {
    id: 'src/utils/relationship-status:engaged',
    description: 'relationship status engaged option',
    defaultMessage: 'Engaged',
  },
  married: {
    id: 'src/utils/relationship-status:married',
    description: 'relationship status married option',
    defaultMessage: 'Married',
  },
  other: {
    id: 'src/utils/relationship-status:other',
    description: 'relationship status other option',
    defaultMessage: 'Other',
  },
});
