import { Box } from '@match/react-component-library';
import styled from 'packages/theming';
import { MEDIA_QUERY } from 'modules/constants/media-queries';

const FieldContainer = styled(Box)(({ theme }) => ({
  [MEDIA_QUERY.MOBILE]: {
    marginRight: theme.space.none,
    marginBottom: theme.space.xlarge,
  },
}));

export default FieldContainer;
