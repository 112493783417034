import { css, keyframes, Global, ClassNames, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@match/react-component-library';
import { Theme as RCLTheme } from '@match/react-component-library/components/theme/types/theme';

declare module '@emotion/react' {
  export interface Theme extends RCLTheme {}
}

export { useTheme } from '@emotion/react';

/**
 * Wraps 3rd party libraries.
 * 1. Incase we need to replace emotion with a similar api.
 * 2. Incase Emotion exports change. Emotion 11 contains named export changes.
 * 3. Apply Theme typings for useTheme(), and styled() globally.
 * 4. Uniformity with matchnode
 */
export { ClassNames, css, Global, jsx, keyframes, ThemeProvider };

export default styled;
