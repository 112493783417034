import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useField } from 'formik';
import { constants, Input, OptionProps, Select as RCLSelect } from '@match/react-component-library';
import { FieldContainer } from 'modules/components/StoryForm/components/FieldContainer';

const { SPACE } = constants;

interface SelectProps {
  hasMargin?: boolean;
  name: string;
  options: Array<{ id: number; name: string }>;
  placeholder: string;
  required?: boolean;
  textField?: boolean;
}

const Select = ({ hasMargin, name, options, placeholder, required, textField }: SelectProps) => {
  const intl = useIntl();
  // using _ or _var doesn't satisfy array destructure linting, omitting it all together does
  const [, { error, touched, value }, { setTouched, setValue }] = useField(name);

  const currentOption = options.find((option) => option.id === value);

  const handleSelect = (item?: OptionProps<{}>) => {
    if (item && item.value) {
      if (textField) {
        setValue(item.text);
      } else {
        setValue(Number(item.value));
      }
    }
  };

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <FieldContainer flex={1} marginRight={hasMargin ? SPACE.XLARGE : SPACE.NONE}>
      <RCLSelect
        id={name}
        buildInputElement={(props) => <Input variant="variant1" {...props} />}
        buttonAriaLabelClear={intl.formatMessage(messages.clearFieldLabel)}
        buttonAriaLabelDownArrow={intl.formatMessage(messages.downLabel)}
        buttonAriaLabelUpArrow={intl.formatMessage(messages.upLabel)}
        error={!!error && !!touched}
        inputElement={<Input variant="default" name="customselect1" />}
        isCombobox
        name={name}
        onInputBlur={handleBlur}
        onSelect={handleSelect}
        placeholder={`${placeholder}${required ? ' *' : ''}`}
        defaultSelectedKey={value}
        defaultSelectedText={currentOption ? currentOption.name : undefined}
      >
        {options.map(({ id, name }) => (
          <RCLSelect.Option key={id} value={id} text={name}>
            {name}
          </RCLSelect.Option>
        ))}
      </RCLSelect>
    </FieldContainer>
  );
};

export default Select;

const messages = defineMessages({
  clearFieldLabel: {
    id: 'src/modules/components/StoryForm/components/Select/Select:clearFieldLabel',
    description: 'clear field label',
    defaultMessage: 'Clear Field',
  },
  downLabel: {
    id: 'src/modules/components/StoryForm/components/Select/Select:downLabel',
    description: 'down label',
    defaultMessage: 'Down',
  },
  upLabel: {
    id: 'src/modules/components/StoryForm/components/Select/Select:upLabel',
    description: 'up label',
    defaultMessage: 'Up',
  },
});
