import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Box, constants, Typography } from '@match/react-component-library';
import { SiteCode, SITECODE } from '@match/app-rules-resolver';
import { useSiteCode } from 'modules/hooks';
import { SEMANTIC_COLOR_NAME } from '@match/react-component-library/components/theme/constants';
import styled from 'packages/theming';

const { FONT_SIZE, FONT_WEIGHT, SPACE } = constants;

const TERMS_HEADER = 'terms_header';

interface TermsModalContentProps {
  brandName: string;
}

interface StyledLinkProps {
  siteCode: SiteCode;
}

const StyledLink = styled('a')<StyledLinkProps>(({ theme, siteCode }) => ({
  color: siteCode === SITECODE.STIR ? theme.palette.core1_100 : undefined,
}));

const TermsModalContent = ({ brandName }: TermsModalContentProps) => {
  const { siteCode } = useSiteCode();
  const isStir = siteCode === SITECODE.STIR;
  return (
    <Box
      padding={SPACE.XLARGE}
      maxWidth={1068}
      color={isStir ? SEMANTIC_COLOR_NAME.GRAY_100 : undefined}
    >
      <Box marginY={SPACE.XLARGE}>
        <Typography
          id={TERMS_HEADER}
          as="h1"
          fontWeight={FONT_WEIGHT.BOLD}
          fontSize={FONT_SIZE.XLARGE}
        >
          <FormattedMessage {...messages.termsTitle} values={{ brand: brandName }} />
        </Typography>
      </Box>
      <Typography as="p">
        <FormattedMessage
          {...messages.termsPrimaryParagraph}
          values={{
            aTerms: (...chunks: Array<string | React.ReactElement>) => (
              <StyledLink
                href={`https://www.${brandName}.com/dnws/registration/membagr.aspx`}
                target="_blank"
                siteCode={siteCode}
              >
                {chunks}
              </StyledLink>
            ),
            aPolicy: (...chunks: Array<string | React.ReactElement>) => (
              <StyledLink
                href={`https://www.${brandName}.com/registration/privacystatement.aspx`}
                target="_blank"
                siteCode={siteCode}
              >
                {chunks}
              </StyledLink>
            ),
            brand: brandName,
          }}
        />
      </Typography>

      <Box marginY={SPACE.LARGE}>
        <Typography as="h2" fontWeight={FONT_WEIGHT.BOLD} fontSize={FONT_SIZE.LARGE}>
          <FormattedMessage {...messages.termsSubtitleA} values={{ brand: brandName }} />
        </Typography>
      </Box>
      <Typography as="p">
        <FormattedMessage {...messages.termsBodyA} values={{ brand: brandName }} />
      </Typography>

      <Box marginY={SPACE.LARGE}>
        <Typography as="h2" fontWeight="bold" fontSize="large">
          <FormattedMessage {...messages.termsSubtitleB} />
        </Typography>
      </Box>
      <Typography as="p">
        <FormattedMessage {...messages.termsBodyB} values={{ brand: brandName }} />
      </Typography>

      <Box marginY={SPACE.LARGE}>
        <Typography as="h2" fontWeight="bold" fontSize="large">
          <FormattedMessage {...messages.termsSubtitleC} values={{ brand: brandName }} />
        </Typography>
      </Box>
      <Typography as="p">
        <FormattedMessage {...messages.termsBodyC} values={{ brand: brandName }} />
      </Typography>
      <Box marginY={SPACE.LARGE}>
        <Typography as="h2" fontWeight="bold" fontSize="large">
          <FormattedMessage {...messages.termsSubtitleD} />
        </Typography>
      </Box>
      <Typography as="p">
        <FormattedMessage
          {...messages.termsBodyD}
          values={{
            a: (...chunks: Array<string | React.ReactElement>) => (
              <StyledLink
                href={`mStyledLinkilto:PR@${brandName}.com`}
                target="_top"
                siteCode={siteCode}
              >
                {chunks}
              </StyledLink>
            ),
            brand: brandName,
          }}
        />
      </Typography>
    </Box>
  );
};

export default TermsModalContent;

const messages = defineMessages({
  termsTitle: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsTitle',
    description: 'Title for terms of use modal',
    defaultMessage: '{brand} Success Site Terms of Use',
  },
  termsPrimaryParagraph: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsPrimaryParagraph',
    description: 'primary pragraph of the terms of use modal',
    defaultMessage:
      'The {brand} Success Site (the "Success Site") allows couples who met on {brand} to submit photos of themselves, their stories of success, and tips on how to get the most out of the {brand} experience. In general, the {brand} Success Site offers {brand} couples the chance to share their success with the world! By using the Success Site in any way, you acknowledge that your use is governed by the {brand} Success Site Terms of Use, as well as the <aTerms>{brand}.com Terms</aTerms> of Use Agreement and <aPolicy>Privacy Policy</aPolicy>.',
  },
  termsSubtitleA: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsSubtitleA',
    description: 'Subtitle for section A of terms modal',
    defaultMessage: 'A. Users of the {brand} Success Site.',
  },
  termsBodyA: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsBodyA',
    description: 'Body of section A of terms modal',
    defaultMessage:
      'Couples who met on {brand} can use the Success Site to submit their dating success story, photos and tips and choose to share the materials only with the {brand} team or allow {brand} to share the materials externally on social media and elsewhere as described in Section (C) below.',
  },
  termsSubtitleB: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsSubtitleB',
    description: 'Subtitle for section B of terms modal',
    defaultMessage: 'B. Submission of Success Stories, Photos, and Tips.',
  },
  termsBodyB: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsBodyB',
    description: 'Body of section B of terms modal',
    defaultMessage:
      'To submit a success story to the Success Site, you must provide certain identifying information about yourselves and your relationship (for example, your relationship status). In addition, you must provide (a) your success story, and (b) a photo of yourselves (a-b are collectively referred to as “Your Success Profile”). In addition to Your Success Profile, you may also submit tips on how to get the most out of {brand}. If submitted, tips will be considered part of Your Success Profile. If you choose to allow {brand} to share Your Success Profile externally as described in Section (C) below, {brand} may use only part of your content. As an example, your success story may be used without your photos or success tips or your photo may be used without your success story. You must obtain express permission from every other individual identified in your success story or pictured in any photograph you submit. By submitting content to the Success Site, you agree and represent that (i) {brand} may use all, part, or none of your content, (ii) both members of the couple have agreed to these Terms of Use, (iii) both members of the couple have agreed to make their story and photo public (if you choose External Use as defined below), (iv) all information and materials submitted are true and accurate, and (v) the submission does not conflict with any obligation or restriction you may have. You agree to indemnify and hold {brand} harmless for any damages resulting from your breach of this agreement or if any of these representations is false.',
  },
  termsSubtitleC: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsSubtitleC',
    description: 'Subtitle for section C of terms modal',
    defaultMessage: 'C. Submission of Success Stories, Photos, and Tips.',
  },
  termsBodyC: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsBodyC',
    description: 'Body of section C of terms modal',
    defaultMessage:
      'By submitting Your Success Profile and choosing the option to share Your Social Profile in social media and elsewhere (“External Use”), each member of the success couple gives {brand} the unlimited right and permission to use Your Success Profile (or any part thereof) (i) on the {brand} website or associated apps; (ii) on the {brand} blog; (iii) in social media, and (iv) in email communications to {brand} members. In addition, if you choose External Use, you give {brand} the right to (a) provide your contact information to the media (provided you give written consent, email is sufficient), (b) contact you at your address (if provided), and (c) transfer Your Success Profile, or parts thereof, as necessary to effect the uses described in this section. {brand} may make changes to Your Success Profile, such as re-phrasing or condensing your story. {brand} may use Your Success Profile in perpetuity, but is not required to use it, or any part of it. Content submitted to the Success Site and used by {brand} externally will remain available as posted or published until {brand} removes such content. {brand} reserves the right to discontinue the Success Site at any time.',
  },
  termsSubtitleD: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsSubtitleD',
    description: 'Subtitle for section D of terms modal',
    defaultMessage: 'D. Submission of Success Stories, Photos, and Tips.',
  },
  termsBodyD: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:termsBodyD',
    description: 'Body of section D of terms modal',
    defaultMessage:
      'Please let us know if your relationship ends by contacting us <a>here</a>. We will remove uses of your Success Profile.',
  },
  closeBtn: {
    id:
      'src/modules/components/StoryForm/components/TermsSection/components/TermsModal/TermsModal:closeBtn',
    description: 'Label for modal close button',
    defaultMessage: 'Close',
  },
});
