import { CultureCode, CULTURE_CODE } from '@match/app-rules-resolver';

interface UseGetLocationDisplayNameProps {
  location: {
    CityName: string;
    CountryName: string;
    StateName: string;
  };
  hideCountry?: boolean;
}

export const useGetLocationDisplayName = (cultureCode: CultureCode) => ({
  location,
  hideCountry = false,
}: UseGetLocationDisplayNameProps) => {
  const { CityName: cityName, StateName: stateName, CountryName: countryName } = location;

  if (!cityName && !stateName && !countryName) {
    return '';
  }

  let separator = ', ';
  let countryFirst = false;

  const isEnglish = () => {
    const reg = new RegExp(/[a-zA-Z]/);
    return [cityName, stateName, countryName].some(
      (location) => !!location && location.charAt(0).match(reg)
    );
  };

  if (cultureCode === CULTURE_CODE.JAPANESE && !isEnglish()) {
    separator = cityName && stateName ? '' : '、';
    countryFirst = true;
  } else if (cultureCode === CULTURE_CODE.KOREAN && !isEnglish()) {
    separator = ' ';
    countryFirst = true;
  } else if (cultureCode === CULTURE_CODE.CHINESE_TRADITIONAL && !isEnglish()) {
    separator = '';
    countryFirst = true;
  }

  if (cityName && stateName) {
    return `${cityName}${separator}${stateName}`;
  } else if (cityName) {
    return hideCountry
      ? cityName
      : `${countryFirst ? countryName : cityName}${separator}${
          countryFirst ? cityName : countryName
        }`;
  } else if (stateName) {
    return hideCountry
      ? stateName
      : `${countryFirst ? countryName : stateName}${separator}${
          countryFirst ? stateName : countryName
        }`;
  } else {
    return countryName || '';
  }
};

// TODO add some testing
