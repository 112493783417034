import React from 'react';
import { useField } from 'formik';
import { constants, Input as RCLInput } from '@match/react-component-library';
import { FieldContainer } from 'modules/components/StoryForm/components/FieldContainer';
import { removeDangerousSpecialCharacters } from 'utils/remove-dangerous-special-characters';

const { SPACE } = constants;

interface InputProps {
  autoFocus?: boolean;
  hasMargin?: boolean;
  ignoreSpecialCharacterFilter?: boolean;
  name: string;
  parser?: (value: string, prev: string) => string;
  placeholder: string;
  required?: boolean;
  type?: string;
}

const Input = ({
  autoFocus,
  hasMargin,
  ignoreSpecialCharacterFilter,
  name,
  parser,
  placeholder,
  required,
  type = 'text',
}: InputProps) => {
  const [{ onBlur, value }, { error, touched }, { setValue }] = useField(name);

  const handleChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      const newValue = ignoreSpecialCharacterFilter
        ? e.currentTarget.value
        : removeDangerousSpecialCharacters(e.currentTarget.value);
      if (parser) {
        setValue(parser(newValue, value));
      } else {
        setValue(newValue);
      }
    }
  };

  return (
    <FieldContainer flex={1} marginRight={hasMargin ? SPACE.XLARGE : SPACE.NONE}>
      <RCLInput
        autoFocus={autoFocus}
        hasError={!!error && !!touched}
        id={name}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={`${placeholder}${required ? ' *' : ''}`}
        type={type}
        value={value}
        variant="default"
        width="100%"
      />
    </FieldContainer>
  );
};

export default Input;
