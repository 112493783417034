import React, { FunctionComponent } from 'react';
import { SITECODE } from '@match/app-rules-resolver';
import { Splash, StirSplash } from 'modules/components/Splash';
import { useSiteCode } from 'modules/hooks';

interface SplashFactoryProps {
  onButtonClick: () => void;
}

const SplashFactory: FunctionComponent<SplashFactoryProps> = ({ onButtonClick }) => {
  const { siteCode } = useSiteCode();

  switch (siteCode) {
    case SITECODE.STIR:
      return <StirSplash onButtonClick={onButtonClick} />;
    default:
      return <Splash onButtonClick={onButtonClick} />;
  }
};

export default SplashFactory;
