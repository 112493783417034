import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useField } from 'formik';
import { constants, Input, OptionProps, Select } from '@match/react-component-library';
import { CultureCode } from '@match/app-rules-resolver';
import { MatchTypeAhead, SuggestionParams, TypeAheadSearchResponse } from '@match/react-type-ahead';
import { FieldContainer } from 'modules/components/StoryForm/components/FieldContainer';
import { useGetLocationDisplayName } from 'modules/hooks';
import { ElasticSearchLocationResponse } from 'types';
import api from 'utils/api-services';

const { SPACE } = constants;

interface LocationTypeAheadProps {
  id: string;
  hasMargin?: boolean;
  name: string;
  onError: () => void;
  placeholder: string;
  required?: boolean;
}

const LocationTypeAhead = ({
  id,
  hasMargin,
  name,
  onError,
  placeholder,
  required,
}: LocationTypeAheadProps) => {
  const intl = useIntl();
  const [{ onBlur }, { value, error, touched }, { setValue }] = useField(name);

  const getLocationDisplayName = useGetLocationDisplayName(intl.locale as CultureCode);

  const [currentLocation, setCurrentLocation] = useState('');

  const getSuggestionsFromResponse = (
    res: TypeAheadSearchResponse<ElasticSearchLocationResponse>
  ) => {
    if (!res.response || !res.response.data) {
      return [];
    }

    return res.response.data.hits.hits.map(({ _source }) => {
      const displayName = getLocationDisplayName({ location: _source });
      return {
        value: JSON.stringify({
          location: displayName,
          latitude: _source.Latitude,
          longitude: _source.Longitude,
          countryCode: _source.CountryCode,
        }),
        displayName,
        optionLabel: displayName,
      };
    });
  };

  const makeSuggestionsRequest = async (params: SuggestionParams) => {
    let response;
    try {
      response = await api.elasticSearchLocation(params.q, intl.locale as CultureCode);
    } catch (err) {
      console.warn(err);
      onError();
      response = {};
    }

    return { response };
  };

  const handleInputChange = (
    currentVal: string,
    onInputChange: (currentInputString: string) => void
  ) => {
    setCurrentLocation(currentVal);
    setValue('');
    onInputChange(currentVal);
  };

  const handleSelect = (item?: OptionProps<{}>) => {
    if (item && item.text) {
      setCurrentLocation('');
      setValue(String(item.value));
    }
  };

  const handleResetInput = () => {
    setCurrentLocation('');
    setValue('');
  };

  return (
    <FieldContainer flex={1} marginRight={hasMargin ? SPACE.XLARGE : SPACE.NONE}>
      <MatchTypeAhead
        minSearchLength={1}
        maxResults={5}
        onSelect={() => null}
        getSuggestionsFromResponse={getSuggestionsFromResponse}
        makeSuggestionsRequest={makeSuggestionsRequest}
      >
        {({ suggestionsList, onInputChange }) => (
          <Select
            id={id}
            buttonAriaLabelClear={intl.formatMessage(messages.clearFieldLabel)}
            buttonAriaLabelDownArrow={intl.formatMessage(messages.downLabel)}
            buttonAriaLabelUpArrow={intl.formatMessage(messages.upLabel)}
            defaultSelectedText={value ? JSON.parse(value).location : currentLocation}
            error={!!error && !!touched}
            filter={false}
            minSearchLength={1}
            name={name}
            onInputChange={(currentVal) => handleInputChange(currentVal, onInputChange)}
            onResetInput={handleResetInput}
            onSelect={handleSelect}
            placeholder={`${placeholder}${required ? ' *' : ''}`}
            buildInputElement={(props) => (
              <Input
                {...props}
                autoComplete="new-password"
                onBlur={onBlur}
                variant="variant2"
                width="100%"
              />
            )}
          >
            {suggestionsList.map((item) => (
              <Select.Option key={item.value} value={item.value} text={item.displayName}>
                {item.displayName}
              </Select.Option>
            ))}
          </Select>
        )}
      </MatchTypeAhead>
    </FieldContainer>
  );
};

export default LocationTypeAhead;

const messages = defineMessages({
  clearFieldLabel: {
    id:
      'src/modules/components/StoryForm/components/LocationTypeAhead/LocationTypeAhead:clearFieldLabel',
    description: 'clear field label',
    defaultMessage: 'Clear Field',
  },
  downLabel: {
    id:
      'src/modules/components/StoryForm/components/LocationTypeAhead/LocationTypeAhead:clearFieldLabel',
    description: 'down label',
    defaultMessage: 'Down',
  },
  upLabel: {
    id:
      'src/modules/components/StoryForm/components/LocationTypeAhead/LocationTypeAhead:clearFieldLabel',
    description: 'up label',
    defaultMessage: 'Up',
  },
});
