import React from 'react';
import { css, Global, useTheme } from 'packages/theming';
import { constants } from '@match/react-component-library';

const { SEMANTIC_COLOR_NAME } = constants;

interface GlobalCssResetProps {
  anchors?: boolean;
  borderBox?: boolean;
  children?: React.ReactNode;
  images?: boolean;
  lists?: boolean;
  margins?: boolean;
  padding?: boolean;
  withoutFocus?: boolean;
  withoutHover?: boolean;
}

function GlobalCssReset(props: GlobalCssResetProps) {
  const theme = useTheme();
  const resetBorderBox = props.borderBox ? `box-sizing: border-box;` : null;
  const resetMargins = props.margins ? 'margin: 0;' : null;
  const resetPadding = props.padding ? 'padding: 0;' : null;
  const resetLists = props.lists ? 'ol, ul { list-style: none; }' : null;
  const resetImages = props.images ? 'img { border-style: none; }' : null;
  const anchors = props.anchors
    ? `a, a:visited, a:active { color: ${
        theme.palette[SEMANTIC_COLOR_NAME.CTA_PRIMARY]
      }; text-decoration: none; } a:hover { text-decoration: underline}`
    : null;
  const focus = props.withoutFocus
    ? `text-decoration: none !important; outline: 0 !important;`
    : null;
  const buttonFocus = props.withoutFocus ? `box-shadow: inherit !important;` : null;
  const hover = props.withoutHover ? `text-decoration: none !important;` : null;

  return (
    <React.Fragment>
      <Global
        styles={css`
          * {
            ${resetMargins}
            ${resetPadding}
            ${resetBorderBox}
            :focus {
              ${focus}
            },
            :hover {
              ${hover}
            },
          }
          button:focus {
            ${buttonFocus}
          }
          ${resetLists}
          ${resetImages}
          ${anchors}
        `}
      />
    </React.Fragment>
  );
}

export default GlobalCssReset;
