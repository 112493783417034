const SITE: {
  JAPAN: '/jp';
  MATCH: '/match';
  OURTIME: '/ourtime';
  PORTUGUESE: '/pt';
  SPANISH: '/es';
  STIR: 'stir';
} = {
  JAPAN: '/jp',
  MATCH: '/match',
  OURTIME: '/ourtime',
  PORTUGUESE: '/pt',
  SPANISH: '/es',
  STIR: 'stir',
};

export default SITE;
export type Site = typeof SITE[keyof typeof SITE];
