import { ClickHandler, Box, Logo, constants } from '@match/react-component-library';
import { defineMessages, useIntl } from 'react-intl';

import { COLOR } from 'modules/constants/color';
import React from 'react';
import { SITECODE } from '@match/app-rules-resolver';
import logoSrc from './logo.png';
import { useSiteCode } from 'modules/hooks';

const { SEMANTIC_COLOR_NAME, SPACE } = constants;

const MAX_HEADER_WIDTH = 1156;

interface HeaderProps {
  onLogoClick: () => void;
}

const Header = ({ onLogoClick }: HeaderProps) => {
  const intl = useIntl();
  const { siteCode } = useSiteCode();
  const isStir = siteCode === SITECODE.STIR;

  return (
    <Box
      display="flex"
      justifyContent="center"
      marginBottom={SPACE.XLARGE}
      width="100%"
      paddingX={SPACE.XLARGE}
    >
      <Box
        alignItems="flex-end"
        display="flex"
        paddingTop={SPACE.XLARGE}
        paddingBottom={SPACE.XLARGE}
        borderBottom={`1px solid ${COLOR.HEADER_BORDER}`}
        flex={1}
        maxWidth={MAX_HEADER_WIDTH}
      >
        <ClickHandler onClick={onLogoClick}>
          <Box width="100%" height="100%" backgroundColor={SEMANTIC_COLOR_NAME.TRANSPARENT}>
            {isStir ? (
              <Logo height={48} width={100} color={SEMANTIC_COLOR_NAME.CORE3_100} />
            ) : (
              <img src={logoSrc} height={60} alt={intl.formatMessage(messages.logoAlt)} />
            )}
          </Box>
        </ClickHandler>
      </Box>
    </Box>
  );
};

export default Header;

const messages = defineMessages({
  logoAlt: {
    id: 'src/modules/components/Header/Header:logoAlt',
    description: 'Alt test for header logo',
    defaultMessage: 'matchmade success stories',
  },
});
