import React, { Component } from 'react';
import Dropzone, { ImageFile } from 'react-dropzone';

const MIN_PHOTO_SIZE_BYTES = 1000; // 1KB
const MAX_PHOTO_SIZE_BYTES = 5000000; // 5MB
export const MIN_PHOTO_SIZE_HUMAN_READABLE = '1KB';
export const MAX_PHOTO_SIZE_HUMAN_READABLE = '5MB';
export const PHOTO_TYPES_ACCEPTED_HUMAN_READABLE = ['jpg', 'png', 'bmp', 'gif'];

interface DropZoneState {
  draggedFiles: ImageFile[];
  acceptedFiles: ImageFile[];
  rejectedFiles: ImageFile[];
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}

export interface Props {
  onSuccessDroppedFiles: (files: ImageFileBase64[]) => void;
  onErrorDroppedFiles: (files: ImageFile[], hasValidPhotos: boolean) => void;
  preHandleDroppedFilesCallback?: (accepted: ImageFile[], rejected: ImageFile[]) => void;
  children: (dropzoneState: DropZoneState) => React.ReactNode;
  allowMultipleFiles?: boolean;
  minPhotoSizeBytes?: number;
  maxPhotoSizeBytes?: number;
  acceptedFileTypes?: string;
  setDropzoneRef?: (ref: any) => void;
  readFileAsBase64Url?: boolean;
  enablePreview?: boolean;
}

export interface ImageFileBase64 extends ImageFile {
  dataUrl?: string; // base64 of image file
  fileName?: string;
}

export interface ImageFileWithError extends ImageFileBase64 {
  errorType?: PhotoErrorCodeType;
}

// Errors are mapped to error response from api/userphotos
export const PHOTO_ERROR: {
  PHOTO_UNKNOWN: 0;
  PHOTO_DUPLICATE: 1;
  PHOTO_AT_MAX_ALLOWED: 2;
  PHOTO_FAILED_FETCH_URL: 3;
  PHOTO_FAILED_ADD_CAPTION: 4;
  PHOTO_SIZE_INVALID: 5;
  PHOTO_TYPE_INVALID: 6;
  PHOTO_TYPE_NO_PHOTOS: 7;
  FACEBOOK_DUPLICATE: 8;
} = {
  PHOTO_UNKNOWN: 0,
  PHOTO_DUPLICATE: 1,
  PHOTO_AT_MAX_ALLOWED: 2,
  PHOTO_FAILED_FETCH_URL: 3,
  PHOTO_FAILED_ADD_CAPTION: 4,
  PHOTO_SIZE_INVALID: 5,
  PHOTO_TYPE_INVALID: 6,
  PHOTO_TYPE_NO_PHOTOS: 7,
  FACEBOOK_DUPLICATE: 8,
};

export type PhotoErrorCodeType = typeof PHOTO_ERROR[keyof typeof PHOTO_ERROR];

export interface PhotoErrorType {
  name: string;
  errorType?: typeof PHOTO_ERROR[keyof typeof PHOTO_ERROR];
}

export default class PhotoUploader extends Component<Props> {
  static defaultProps = {
    readFileAsBase64Url: true,
  };

  handleOnDrop = (accepted: ImageFile[], rejected: ImageFile[]) => {
    if (this.props.preHandleDroppedFilesCallback) {
      this.props.preHandleDroppedFilesCallback(accepted, rejected);
    }

    const enablePreview = this.props.enablePreview || false;
    if (!enablePreview) {
      try {
        accepted.forEach((file) => {
          if (file.preview) {
            window.URL.revokeObjectURL(file.preview);
          }
        });
        rejected.forEach((file) => {
          if (file.preview) {
            window.URL.revokeObjectURL(file.preview);
          }
        });
      } catch (err) {}
    }
    if (rejected && rejected.length) {
      this.handleRejectedFiles(rejected, accepted.length > 0);
    }
    if (accepted && accepted.length) {
      this.handleAcceptedFiles(accepted);
    }
  };

  handleAcceptedFiles = async (files: ImageFile[]) => {
    if (this.props.readFileAsBase64Url) {
      const readFile = (file: ImageFile): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.onerror = () => {
            reject();
          };
          reader.readAsDataURL(file);
        });
      };

      const promisedArray = files.map(async (file: any) => {
        try {
          const dataBase64 = await readFile(file);
          file.fileName = file.name;
          file.dataUrl = dataBase64;

          return file;
        } catch (err) {
          return file;
        }
      });

      const newImageFiles = await Promise.all(promisedArray);

      this.props.onSuccessDroppedFiles(newImageFiles);
    } else {
      this.props.onSuccessDroppedFiles(files);
    }
  };

  handleRejectedFiles = (files: any, hasValidPhotos: boolean) => {
    const minSizeBytes = this.props.minPhotoSizeBytes || MIN_PHOTO_SIZE_BYTES;
    const maxSizeBytes = this.props.maxPhotoSizeBytes || MAX_PHOTO_SIZE_BYTES;

    this.props.onErrorDroppedFiles(
      files.map((file: any) => {
        if (file.size < minSizeBytes || file.size > maxSizeBytes) {
          file.errorType = PHOTO_ERROR.PHOTO_SIZE_INVALID;
          return file;
        } else {
          file.errorType = PHOTO_ERROR.PHOTO_TYPE_INVALID;
          return file;
        }
      }),
      hasValidPhotos
    );
  };

  render() {
    const minSizeBytes = this.props.minPhotoSizeBytes || MIN_PHOTO_SIZE_BYTES;
    const maxSizeBytes = this.props.maxPhotoSizeBytes || MAX_PHOTO_SIZE_BYTES;

    const allowMultipleFiles = this.props.allowMultipleFiles || false;
    const acceptedFileTypes =
      this.props.acceptedFileTypes || 'image/gif, image/jpeg, image/jpg, image/png, image/bmp';
    return (
      <Dropzone
        {...(this.props.setDropzoneRef ? { ref: this.props.setDropzoneRef } : {})}
        onDrop={this.handleOnDrop}
        multiple={allowMultipleFiles}
        accept={acceptedFileTypes}
        style={{}}
        minSize={minSizeBytes}
        maxSize={maxSizeBytes}
      >
        {(dropzoneState: DropZoneState) => {
          return this.props.children(dropzoneState);
        }}
      </Dropzone>
    );
  }
}
