import React, { useEffect, useState } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import range from 'lodash/range';
import { Box, constants, Icon, Loading } from '@match/react-component-library';
import { PhotoUploader as BasePhotoUploader } from './react-photo-uploader'; // TODO This should eventually be a working package
import { ImageFileBase64, ImageFileWithError } from './react-photo-uploader/PhotoUploader';
import styled from 'packages/theming';
import { PhotoPreview } from './components';
import { UploadedPhoto } from 'types/index';
import api from 'utils/api-services';
import { COLOR } from 'modules/constants/color';

const { ICON_NAMES, RADII, SEMANTIC_COLOR_NAME, SPACE } = constants;

interface PhotoUploaderProps {
  onChange: (value: UploadedPhoto[]) => void;
  onError: (error: string) => void;
  photoLimit: number;
  photos: UploadedPhoto[];
}

const PhotoUploader = ({ onChange, onError, photoLimit, photos }: PhotoUploaderProps) => {
  const intl = useIntl();

  const [inProgressCount, setInProgressCount] = useState<number>(0);
  const [uploadedPhotos, setUploadedPhotos] = useState<UploadedPhoto[]>([]);

  useEffect(() => {
    if (photos.length) {
      setUploadedPhotos(photos);
    }
  }, []);

  useEffect(() => {
    if (inProgressCount > 0) {
      onChange([]);
    }
  }, [inProgressCount]);

  useEffect(() => {
    onChange(uploadedPhotos);
  }, [uploadedPhotos, inProgressCount]);

  const handleSuccessDrop = async (newPhotos: ImageFileBase64[]) => {
    if (newPhotos.length + uploadedPhotos.length > photoLimit) {
      newPhotos = newPhotos.slice(0, photoLimit - uploadedPhotos.length);
      onError(intl.formatMessage(messages.errorMaxAllowed));
    }

    setInProgressCount(inProgressCount + newPhotos.length);

    try {
      const newUploadedPhotoIds = await api.createPhotos({
        photos: newPhotos.map(({ fileName, dataUrl, preview }) => ({
          name: fileName,
          dataUrl,
          preview,
        })),
      });

      setInProgressCount(inProgressCount);
      setUploadedPhotos([
        ...uploadedPhotos,
        ...newUploadedPhotoIds.map((id, index) => ({
          userPhotoID: id,
          preview: newPhotos[index].preview as string,
        })),
      ]);
    } catch (err) {
      onError(intl.formatMessage(messages.defaultError));
    }
  };

  const handleUploadError = (files: ImageFileWithError[]) => {
    let error = null;
    switch (files[0].errorType) {
      case 1:
        error = messages.errorDuplicate;
        break;
      case 2:
        error = messages.errorMaxAllowed;
        break;
      case 5:
        error = messages.errorMaxSize;
        break;
      case 6:
        error = messages.errorInvalidType;
        break;
      default:
        error = messages.defaultError;
    }

    onError(intl.formatMessage(error));
  };

  const handleRemovePhoto = (index: number) => {
    setUploadedPhotos(uploadedPhotos.filter((_photo, i) => i !== index));
  };

  const totalCount = uploadedPhotos.length + inProgressCount;

  return (
    <Box marginBottom={SPACE.XLARGE}>
      <BasePhotoUploader
        onSuccessDroppedFiles={handleSuccessDrop}
        onErrorDroppedFiles={handleUploadError}
        enablePreview
        allowMultipleFiles
      >
        {() => (
          <label aria-label={intl.formatMessage(messages.uploadSummary, { totalCount }) as string}>
            <Box
              display="flex"
              width="100%"
              border={`2px dashed ${COLOR.GRAY}`}
              borderRadius={RADII.ROUND_CORNER_1X}
              paddingX={SPACE.MEDIUM}
              paddingTop={SPACE.MEDIUM}
              marginBottom={SPACE.SMALL}
              flexWrap="wrap"
            >
              {uploadedPhotos.map(({ preview }, i) => (
                <PhotoPreview
                  path={preview}
                  onClick={() => handleRemovePhoto(i)}
                  preview={preview}
                />
              ))}
              {range(0, inProgressCount).map((i) => (
                <PhotoBox
                  key={i}
                  center
                  width={64}
                  height={64}
                  borderRadius={RADII.ROUND_CORNER_1X}
                  marginRight={SPACE.MEDIUM}
                  marginBottom={SPACE.MEDIUM}
                >
                  <Loading size="xxsmall" />
                </PhotoBox>
              ))}
              {totalCount < photoLimit && (
                <PhotoBox
                  marginRight={SPACE.MEDIUM}
                  marginBottom={SPACE.MEDIUM}
                  center
                  width={64}
                  height={64}
                  borderRadius={RADII.ROUND_CORNER_1X}
                >
                  <PlusIcon
                    color={SEMANTIC_COLOR_NAME.CTA_PRIMARY}
                    size="small"
                    name={ICON_NAMES.cross}
                  />
                </PhotoBox>
              )}
            </Box>
          </label>
        )}
      </BasePhotoUploader>
    </Box>
  );
};

const PlusIcon = styled(Icon)({
  transform: 'rotate(45deg)',
});

const PhotoBox = styled(Box)({
  cursor: 'pointer',
  backgroundColor: COLOR.PHOTO_BACKGROUND,
  border: `1px solid ${COLOR.PHOTO_BORDER}`,
});

export default PhotoUploader;

const messages = defineMessages({
  errorDuplicate: {
    id: 'src/modules/components/StoryForm/components/PhotoUploader/PhotoUploader:errorDuplicate',
    description: 'Error on photo upload when file has already been uploaded',
    defaultMessage: 'Unable to upload duplicate photo',
  },
  errorMaxAllowed: {
    id: 'src/modules/components/StoryForm/components/PhotoUploader/PhotoUploader:errorMaxAllowed',
    description: 'Error on photo upload when maximum photo count has already been uploaded',
    defaultMessage: 'Maximum number of photos have been uploaded',
  },
  errorMaxSize: {
    id: 'src/modules/components/StoryForm/components/PhotoUploader/PhotoUploader:errorMaxSize',
    description: 'Error on photo upload when photo file size is too big, max size is 5mb',
    defaultMessage: 'A photo was too large to upload, maximum size is 5mb',
  },
  errorInvalidType: {
    id: 'src/modules/components/StoryForm/components/PhotoUploader/PhotoUploader:errorInvalidType',
    description: 'Error on photo upload when file uploaded is not valid image file type',
    defaultMessage: 'Invalid file type, file must be an image',
  },
  defaultError: {
    id: 'src/modules/components/StoryForm/components/PhotoUploader/PhotoUploader:defaultError',
    description: 'error message indicating something is wrong with the site',
    defaultMessage:
      "It's not you, it's us. Try clicking the buttons again in a few minutes, or refreshing your browser.",
  },
  uploadSummary: {
    id: 'src/modules/components/StoryForm/components/PhotoUploader/PhotoUploader:uploadSummary',
    description: 'Summary of current state of photo uploader',
    defaultMessage: 'photo upload {totalCount} of 5',
  },
});
