export const MEDIA_QUERY: {
  MOBILE: '@media (max-width: 720px)';
  TABLET: '@media (max-width: 992px)';
  MEDIUM_DEVICE: '@media (max-width: 1200px)';
} = {
  MOBILE: '@media (max-width: 720px)',
  TABLET: '@media (max-width: 992px)',
  MEDIUM_DEVICE: '@media (max-width: 1200px)',
};

export const PLATFORM_MAX_WIDTH: {
  MOBILE: 720;
  TABLET: 992;
  MEDIUM_DEVICE: 1200;
} = {
  MOBILE: 720,
  TABLET: 992,
  MEDIUM_DEVICE: 1200,
};
