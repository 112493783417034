import React from 'react';
import { Box } from '@match/react-component-library';
import { CULTURE_CODE } from '@match/app-rules-resolver';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import styled from 'packages/theming';
import { useLocale } from 'modules/hooks';
import { Quote } from '../../components';
import { MEDIA_QUERY } from 'modules/constants/media-queries';
import leftPhotoSrc from './splash-photo-left.jpg';
import centerPhotoSrc from './splash-photo-center.jpg';
import rightPhotoSrc from './splash-photo-right.jpg';
import jpLeftPhotoSrc from './jp-splash-photo-left.jpg';
import jpCenterPhotoSrc from './jp-splash-photo-center.jpg';
import jpRightPhotoSrc from './jp-splash-photo-right.jpg';

const SplashPhotos = () => {
  const intl = useIntl();
  const photoAlt = intl.formatMessage(messages.successPhotoAlt);
  const { locale } = useLocale();

  const PhotosAndQuotes = () => (
    <React.Fragment>
      <PhotoAndQuoteBox>
        <CenterPhoto
          src={locale === CULTURE_CODE.JAPANESE ? jpCenterPhotoSrc : centerPhotoSrc}
          alt={photoAlt}
        />
        <TopQuoteBox>
          <Quote couplesName={messages.topQuoteCoupleName.defaultMessage} locale={locale}>
            <FormattedMessage {...messages.topQuote} />
          </Quote>
        </TopQuoteBox>
      </PhotoAndQuoteBox>
      <PhotoAndQuoteBox>
        <LeftPhoto
          src={locale === CULTURE_CODE.JAPANESE ? jpLeftPhotoSrc : leftPhotoSrc}
          alt={photoAlt}
        />
        <BottomLeftQuoteBox>
          <Quote couplesName={messages.bottomLeftQuoteCoupleName.defaultMessage} locale={locale}>
            <FormattedMessage {...messages.bottomLeftQuote} />
          </Quote>
        </BottomLeftQuoteBox>
      </PhotoAndQuoteBox>
      <PhotoAndQuoteBox>
        <RightPhoto
          src={locale === CULTURE_CODE.JAPANESE ? jpRightPhotoSrc : rightPhotoSrc}
          alt={photoAlt}
        />
        <BottomRightQuoteBox>
          <Quote couplesName={messages.bottomRightQuoteCoupleName.defaultMessage} locale={locale}>
            <FormattedMessage {...messages.bottomRightQuote} />
          </Quote>
        </BottomRightQuoteBox>
      </PhotoAndQuoteBox>
    </React.Fragment>
  );

  return (
    <SplashImagesBox width={556} height={635} position="relative" marginX="auto">
      <PhotosAndQuotes />
    </SplashImagesBox>
  );
};

const SplashImagesBox = styled(Box)(({ theme }) => ({
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    width: 513,
    height: 516,
  },
  [MEDIA_QUERY.TABLET]: {
    width: '55%',
    marginRight: theme.space.none,
    height: 400,
  },
  [MEDIA_QUERY.MOBILE]: {
    width: '100%',
    height: 'auto',
  },
}));

const PhotoAndQuoteBox = styled(Box)({
  [MEDIA_QUERY.MOBILE]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '82px',
    alignItems: 'center',
  },
});

const Photo = styled('img')(({ theme }) => ({
  borderRadius: theme.radii.roundCorner2x,
  width: 266,
  height: 376,
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    width: 218,
    height: 300,
  },
  [MEDIA_QUERY.TABLET]: {
    width: 135,
    height: 190,
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 118,
    height: 163,
    position: 'static',
    marginRight: theme.space.xxlarge,
  },
}));

const RightPhoto = styled(Photo)({
  position: 'absolute',
  right: '-1px',
  bottom: '-15px',
  zIndex: 1,
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    right: 32,
  },
  [MEDIA_QUERY.TABLET]: {
    right: 95,
    bottom: 74,
  },
  [MEDIA_QUERY.MOBILE]: {
    right: 92,
    bottom: 17,
  },
});

const LeftPhoto = styled(Photo)(({ theme }) => ({
  position: 'absolute',
  left: '2px',
  bottom: '-15px',
  zIndex: 3,
  [MEDIA_QUERY.MOBILE]: {
    left: '225px',
    marginLeft: theme.space.xxlarge,
    marginRight: theme.space.none,
    order: 2,
  },
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    left: '24px',
  },
  [MEDIA_QUERY.TABLET]: {
    bottom: '74px',
    left: 'initial',
    right: '245px',
  },
}));

const CenterPhoto = styled(Photo)({
  position: 'absolute',
  right: '191px',
  zIndex: 2,
});

const TopQuoteBox = styled(Box)(() => ({
  position: 'absolute',
  top: '60px',
  right: '-15px',
  width: '170px',
  display: 'flex',
  justifyContent: 'center',
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    right: '-10px',
    top: '20px',
  },
  [MEDIA_QUERY.TABLET]: {
    top: '0px',
    right: '0px',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'block',
    position: 'relative',
    width: '100%',
    right: '0px',
    bottom: '0px',
  },
}));

const BottomLeftQuoteBox = styled(Box)(() => ({
  bottom: '-88px',
  left: '-194px',
  position: 'absolute',
  width: '188px',
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    bottom: '-90px',
    left: '-165px',
  },
  [MEDIA_QUERY.TABLET]: {
    left: 'initial',
    right: '347px',
    bottom: '-62px',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'block',
    position: 'relative',
    width: '100%',
    right: '0px',
    bottom: '0px',
  },
}));

const BottomRightQuoteBox = styled(Box)(() => ({
  bottom: '-124px',
  right: '-13px',
  position: 'absolute',
  width: '370px',
  [MEDIA_QUERY.MEDIUM_DEVICE]: {
    bottom: '-110px',
  },
  [MEDIA_QUERY.TABLET]: {
    width: '320px',
    bottom: '-60px',
    right: '-10px',
  },
  [MEDIA_QUERY.MOBILE]: {
    position: 'relative',
    display: 'block',
    width: '100%',
    right: '0px',
    bottom: '0px',
  },
}));

const messages = defineMessages({
  successPhotoAlt: {
    id: 'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:successPhotoAlt',
    description: 'photo of success story couple',
    defaultMessage: 'Success Couple',
  },
  topQuote: {
    id: 'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:topQuote',
    description: 'user testimonial quote',
    defaultMessage:
      '“We only lived one mile from each other, but without Match, this love story would not have be possible.”',
  },
  topQuoteCoupleName: {
    id: 'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:topQuoteCoupleName',
    description: 'user testimonial quote couple name',
    defaultMessage: 'Tracy and Sadao',
  },
  bottomLeftQuote: {
    id: 'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:bottomLeftQuote',
    description: 'user testimonial quote',
    defaultMessage:
      "“I can now say, without reservation, that I've found my adventure partner for life.”",
  },
  bottomLeftQuoteCoupleName: {
    id:
      'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:bottomLeftQuoteCoupleName',
    description: 'user testimonial quote couple name',
    defaultMessage: 'Heather and Jake',
  },
  bottomRightQuote: {
    id: 'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:bottomRightQuote',
    description: 'user testimonial quote',
    defaultMessage:
      "“I literally gasped when I saw his picture, I can't tell you why. Just the magic of love, I guess.”",
  },
  bottomRightQuoteCoupleName: {
    id:
      'src/modules/components/Splash/components/SplashPhotos/SplashPhotos:bottomRightQuoteCoupleName',
    description: 'user testimonial quote couple name',
    defaultMessage: 'Charla and Alex',
  },
});

export default SplashPhotos;
