// Ripped from Coreweb

export const htmlRegEx = new RegExp(
  '(&(nbsp|amp|quot|lt|gt|#[0-9]+);)|(<|%3C|>|\\\\|/|%22|%3E|%2F|%3c|%3e|{|}|%2f)',
  'g'
); // regex for dangerous characters - this regex comes from match8 code

export const removeDangerousSpecialCharacters = (text: string) => {
  // strip disallowed characters and expressions
  // loop to eliminate inception patterns, for example: &nb&nbsp;sp;
  let oldText,
    newText = text; //  we do this to avoid mutating the value that was passed in
  do {
    oldText = newText;
    newText = newText.replace(htmlRegEx, '');
  } while (newText !== oldText);
  return newText;
};
