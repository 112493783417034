import React, { Fragment } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, constants, Typography } from '@match/react-component-library';
import { CULTURE_CODE, SiteCode, SITECODE } from '@match/app-rules-resolver';
import styled from 'packages/theming';
import { useBrandName, useLocale, useSiteCode } from 'modules/hooks';

const { FONT_SIZE, SPACE, SEMANTIC_COLOR_NAME } = constants;

interface SubmissionSectionProps {
  disabled: boolean;
  onBack: () => void;
  onSubmit: () => void;
}

interface StyledLinkProps {
  siteCode: SiteCode;
}

const StyledLink = styled('a')<StyledLinkProps>(({ theme, siteCode }) => ({
  color: siteCode === SITECODE.STIR ? theme.palette.core1_100 : undefined,
}));

const SubmissionSection = ({ disabled, onBack, onSubmit }: SubmissionSectionProps) => {
  const intl = useIntl();
  const { locale } = useLocale();
  const isJapan = locale === CULTURE_CODE.JAPANESE;
  const { siteCode } = useSiteCode();
  const isStir = siteCode === SITECODE.STIR;
  const { brandName } = useBrandName();

  const contactUsLink = isJapan
    ? 'https://jp.match.com/community/contact-us'
    : `mailto:PR@${brandName}.com`;
  const contactUsLinkTarget = isJapan ? '_blank' : '_top';

  return (
    <Fragment>
      <Box display="flex" marginTop={SPACE.MEDIUM}>
        <Box marginRight={SPACE.MEDIUM}>
          <label
            tabIndex={disabled ? 0 : undefined}
            aria-label={disabled ? intl.formatMessage(messages.submitDisabled) : undefined}
          >
            <StyledButton
              size="xlarge"
              type="submit"
              onClick={onSubmit}
              disabled={disabled}
              color={isStir ? SEMANTIC_COLOR_NAME.CORE1_100 : SEMANTIC_COLOR_NAME.CORE3_100}
            >
              <FormattedMessage {...messages.submitButton} />
            </StyledButton>
          </label>
        </Box>

        {!isStir && (
          <StyledButton
            inverse
            minimal
            onClick={onBack}
            size="xlarge"
            type="reset"
            color={SEMANTIC_COLOR_NAME.CORE3_100}
          >
            <FormattedMessage {...messages.cancelButton} />
          </StyledButton>
        )}
      </Box>
      <Box marginTop={SPACE.LARGE}>
        <Typography fontSize={FONT_SIZE.XXSMALL}>
          <FormattedMessage
            {...messages.havingTrouble}
            values={{
              a: (...chunks: Array<string | React.ReactElement>) => (
                <StyledLink href={contactUsLink} target={contactUsLinkTarget} siteCode={siteCode}>
                  {chunks}
                </StyledLink>
              ),
              brand: brandName,
            }}
          />
        </Typography>
      </Box>
    </Fragment>
  );
};

const StyledButton = styled(Button)({
  minWidth: 105,
});

export default SubmissionSection;

const messages = defineMessages({
  submitButton: {
    id:
      'src/modules/components/StoryForm/components/SubmissionSection/SubmissionSection:submitButton',
    description: 'button to submit story',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id:
      'src/modules/components/StoryForm/components/SubmissionSection/SubmissionSection:cancelButton',
    description: 'button to cancel form',
    defaultMessage: 'Cancel',
  },
  havingTrouble: {
    id:
      'src/modules/components/StoryForm/components/SubmissionSection/SubmissionSection:havingTrouble',
    description: 'contact instructions if the user is unable to submit their story',
    defaultMessage: 'Having trouble? Email us at <a>PR@{brand}.com</a>',
  },
  submitDisabled: {
    id:
      'src/modules/components/StoryForm/components/SubmissionSection/SubmissionSection:submitDisabled',
    description: 'warning that the submit button is disabled',
    defaultMessage: 'submit button disabled',
  },
});
