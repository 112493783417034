import { themeSurprisedCorgi, themeStir } from '@match/react-component-library';
import { SiteCode, SITECODE } from '@match/app-rules-resolver';

export const themeBuilder = (siteCode : SiteCode ) => {
  let theme = themeSurprisedCorgi;
  if (siteCode === SITECODE.STIR) {
    theme = themeStir;
  }
  const successTheme = Object.assign({}, theme);

  successTheme.components.input = {
    ...successTheme.components.input,
    default: {
      ...successTheme.components.input.variant2,
      background: successTheme.palette.core5_50,
      borderBottomColor: successTheme.palette.core3_100,
    },
  };
  successTheme.components.textArea = {
    ...successTheme.components.textArea,
    variant1: {
      ...successTheme.components.textArea.variant1,
      backgroundColor: successTheme.palette.core5_50,
    },
  };
  successTheme.components.checkbox = {
    ...successTheme.components.checkbox,
    checked: {
      backgroundColor: successTheme.palette.core3_25,
      border: '1px solid '.concat(successTheme.palette.core3_100),
      color: successTheme.palette.core5_50,
    },
    unchecked: {
      backgroundColor: successTheme.palette.core5_50,
      border: '1px solid '.concat(successTheme.palette.core3_100),
      color: successTheme.palette.core3_100,
    },
  };
  successTheme.components.radio = {
    ...successTheme.components.radio,
    checked: { border: '1px solid '.concat(successTheme.palette.core3_100) },
    unchecked: { border: '1px solid '.concat(successTheme.palette.core3_100) },
    checkedDot: {
      ...successTheme.components.radio.checkedDot,
      backgroundColor: successTheme.palette.core3_100,
    },
  };

  return successTheme;
};
