import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { constants, Typography } from '@match/react-component-library';
import { Tip } from '../Tip';

const { FONT_SIZE, FONT_WEIGHT } = constants;

export const SC3GiftCardLegal = () => {
  return (
    <React.Fragment>
      <Typography fontSize={FONT_SIZE.SMALL} fontWeight={FONT_WEIGHT.BOLD}>
        <FormattedMessage {...messages.giftCardLegalTitle} />
      </Typography>
      <Tip>
        <FormattedMessage {...messages.giftCardLegalLine1} values={{ br: <br /> }} />
      </Tip>
    </React.Fragment>
  );
};

const messages = defineMessages({
  giftCardLegalTitle: {
    id:
      'src/modules/components/RightRail/components/SC3GiftCardLegal/SC3GiftCardLegal:giftCardLegalTitle',
    description: 'title for gift card block',
    defaultMessage: 'Only users who fulfill all above conditions will receive a gift',
  },
  giftCardLegalLine1: {
    id:
      'src/modules/components/RightRail/components/SC3GiftCardLegal/SC3GiftCardLegal:giftCardLegalLine1',
    description: 'first line of gift card legal block',
    defaultMessage: 'Users we could verify used Match{br}',
  },
});
