import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CULTURE_CODE } from '@match/app-rules-resolver';
import { Box, constants } from '@match/react-component-library';
import styled from 'packages/theming';
import { useLocale } from 'modules/hooks';

const { SPACE } = constants;

const StyledAnchorTag = styled('a')({
  textDecoration: 'none',
});

export const SC3AdditionalFooterLinks = () => {
  const { locale } = useLocale();
  const isJapan = locale === CULTURE_CODE.JAPANESE;
  if (!isJapan) return null;
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      marginLeft={SPACE.LARGE}
    >
      <StyledAnchorTag
        href="https://jp.match.com/registration/privacystatement.aspx"
        target="_blank"
      >
        <FormattedMessage {...messages.privacyPolicyFooterLink} />
      </StyledAnchorTag>
      <StyledAnchorTag href="https://jp.match.com/registration/membagr.aspx" target="_blank">
        <FormattedMessage {...messages.termsAndConditionsFooterLink} />
      </StyledAnchorTag>
      <StyledAnchorTag href="https://jp.match.com/registration/cookiepolicy.aspx" target="_blank">
        <FormattedMessage {...messages.cookiePolicyFooterLink} />
      </StyledAnchorTag>
    </Box>
  );
};

const messages = defineMessages({
  privacyPolicyFooterLink: {
    id:
      'src/modules/components/Footer/SC3AdditionalFooterLinks/SC3AdditionalFooterLinks:privacyPolicyFooterLink',
    description: 'privacy policy footer link',
    defaultMessage: 'Privacy Policy',
  },
  termsAndConditionsFooterLink: {
    id:
      'src/modules/components/Footer/SC3AdditionalFooterLinks/SC3AdditionalFooterLinks:termsAndConditionsFooterLink',
    description: 'terms and conditions footer link',
    defaultMessage: 'Terms and conditions',
  },
  cookiePolicyFooterLink: {
    id:
      'src/modules/components/Footer/SC3AdditionalFooterLinks/SC3AdditionalFooterLinks:cookiePolicyFooterLink',
    description: 'cookie policy footer link',
    defaultMessage: 'Cookie policy',
  },
});
