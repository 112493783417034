import React from 'react';
import { Helmet } from 'react-helmet';
import { CULTURE_CODE } from '@match/app-rules-resolver';
import { useLocale } from 'modules/hooks';

export const OneTrust = () => {
  const { locale } = useLocale();
  const isJapan = locale === CULTURE_CODE.JAPANESE;

  // Legal Japan is wanting a cookie consent banner
  // Not required for all other sitecodes, so returning null if not Japan/SC3
  if (!isJapan) return null;

  return (
    <Helmet>
      {/* OneTrust Cookies Consent Notice start for match.com */}
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="7f436592-82ea-4d50-a34d-85e80f2a608d"
      ></script>
      <script type="text/javascript">function OptanonWrapper() {}</script>
      {/* OneTrust Cookies Consent Notice end for match.com */}
    </Helmet>
  );
};
